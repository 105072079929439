/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { CSVLink } from "react-csv";
import Search from "../components/assets/flowbite_search-outline (1).svg";
import { Triangle } from "react-loader-spinner";
import Chevron from "../components/assets/ep_arrow-up-bold.svg";
import axiosInstance from "../axios";
import ScaReqTable from "../components/Tables/ScaReqTable";
import { Dialog, DialogPanel } from "@headlessui/react";
import { toast } from "react-toastify";

function ScaRequest() {
  const [data, setData] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [newUrl, setNewUrl] = useState(null);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterBy, setFilterBy] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  const [selectedAccount, setSelectedAccount] = useState();
  const [openSelected, setOpenSelected] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const CSVModaldata = selectedAccount ? [selectedAccount] : [];
  const CSVdata = data;

  console.log(selectedAccount);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 500); // 300ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    setLoading(true);
    if (debouncedTerm.length > 0) {
      const fetchResults = async () => {
        try {
          const response = await axiosInstance.get(
            `/dashboard/sca-requests/${debouncedTerm}/`
          );
          console.log(response.data);
          setNextUrl(response.data.next);
          setPrevUrl(response.data.previous);
          setTotal(response.data.count);
          setData(response.data.results);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching search results", error);
        }
      };

      fetchResults();
    } else {
      axiosInstance
        .get(`${newUrl || "dashboard/sca-requests"}`)
        .then((res) => {
          console.log(res);
          setLoading(false);
          setNextUrl(res.data.next);
          setPrevUrl(res.data.previous);
          setTotal(res.data.count);
          setData(res.data.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [debouncedTerm, newUrl, refresh]);

  function updateReceipt() {
    setActionLoading(true);
    toast.info("Please Wait...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    let formdata = new FormData();
    formdata.append("sca_id", selectedAccount?.id);
    formdata.append("status", status);
    formdata.append("comment", comment);
    axiosInstance
      .post(`/dashboard/sca-requests/`, formdata)
      .then((res) => {
        console.log(res);
        setActionLoading(false);

        setComment("");
        setStatus("");
        setRefresh(!refresh);
        setOpenComment(false);
        setOpenSelected(false)
        toast.success("Successfully updated request", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // setSelectedUserAccounts([...res.data.accounts, ...res.data.scas]);
        // setNextUrl(res.data.next);
        // setPrevUrl(res.data.previous);
        // setTotal(res.data.count);
        // setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to update request", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setActionLoading(false);
      });
  }

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="w-[100%] lg:w-[82%]">
        <Topbar />
        <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
          <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
            <div className="flex justify-between px-[16px]">
              <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                SCA Request List
              </h1>
              <div className="flex items-center gap-10 text-[0.875rem]">
                <CSVLink data={CSVdata} filename={"sca_req_data.csv"}>
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
                <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    type="text"
                    placeholder="Search"
                    className="px-3 outline-none font-poppins"
                  />
                </div>
                {/* <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                  Filter
                  <img src={Chevron} alt="" />
                </button> */}
                <div className="flex items-center gap-5 font-poppins font-medium">
                  {prevUrl && (
                    <button
                      onClick={() => {
                        setNewUrl(prevUrl);
                      }}
                      className="bg-white text-[#3EB211] border border-[#3EB211] py-2 px-4 rounded-lg"
                    >
                      Prev
                    </button>
                  )}
                  {/* <div>
                        Showing: {data?.length} users per page
                        <p>Total users: {total.toLocaleString()}</p>
                      </div> */}
                  {nextUrl && (
                    <button
                      onClick={() => {
                        setCount(count + 1);
                        setNewUrl(nextUrl);
                      }}
                      className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <ScaReqTable
                data={data}
                setSelectedAccount={setSelectedAccount}
                setOpenSelected={setOpenSelected}
              />
            )}
          </div>

          <div className="lg:hidden">
            <div className="flex items-center justify-between">
              <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                SCA Request List
              </h1>

              <CSVLink data={CSVdata} filename={"sca_req_data.csv"}>
                <button className="text-[#3EB211] font-poppins font-medium">
                  Export CSV
                </button>
              </CSVLink>
            </div>
            <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
              <div className="flex items-center justify-between">
                <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    type="text"
                    placeholder="Search"
                    className="px-3 outline-none font-poppins"
                  />
                </div>
                {/* <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                  Filter
                  <img src={Chevron} alt="" />
                </button> */}
              </div>
              <div className="flex items-center gap-5 font-poppins font-medium">
                {prevUrl && (
                  <button
                    onClick={() => {
                      setNewUrl(prevUrl);
                    }}
                    className="bg-white text-[#3EB211] border border-[#3EB211] py-2 px-4 rounded-lg"
                  >
                    Prev
                  </button>
                )}
                {nextUrl && (
                  <button
                    onClick={() => {
                      setCount(count + 1);
                      setNewUrl(nextUrl);
                    }}
                    className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <ScaReqTable
                data={data}
                setSelectedAccount={setSelectedAccount}
                setOpenSelected={setOpenSelected}
              />
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={openSelected}
        onClose={() => {
          setOpenSelected(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/50 justify-center p-4">
          <DialogPanel className="rounded-lg font-poppins space-y-6 border bg-white p-6 w-[600px] lg:max-h-[700px] max-h-[600px] overflow-y-scroll">
            <div className="flex items-center justify-between">
              <CSVLink data={CSVModaldata} filename={"fca_data.csv"}>
                <button className="text-[#3EB211] font-poppins font-medium">
                  Export CSV
                </button>
              </CSVLink>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="cursor-pointer"
                onClick={() => {
                  setOpenSelected(false);
                }}
              >
                <path
                  d="M12.7001 3.97283C12.6385 3.91103 12.5652 3.862 12.4846 3.82854C12.4039 3.79509 12.3175 3.77787 12.2301 3.77787C12.1428 3.77787 12.0564 3.79509 11.9757 3.82854C11.8951 3.862 11.8218 3.91103 11.7601 3.97283L8.50015 7.22616L5.24015 3.96616C5.17843 3.90444 5.10515 3.85548 5.02451 3.82208C4.94387 3.78868 4.85744 3.77148 4.77015 3.77148C4.68286 3.77148 4.59643 3.78868 4.51579 3.82208C4.43514 3.85548 4.36187 3.90444 4.30015 3.96616C4.23843 4.02789 4.18947 4.10116 4.15606 4.1818C4.12266 4.26245 4.10547 4.34888 4.10547 4.43616C4.10547 4.52345 4.12266 4.60988 4.15606 4.69053C4.18947 4.77117 4.23843 4.84444 4.30015 4.90616L7.56015 8.16616L4.30015 11.4262C4.23843 11.4879 4.18947 11.5612 4.15606 11.6418C4.12266 11.7224 4.10547 11.8089 4.10547 11.8962C4.10547 11.9835 4.12266 12.0699 4.15606 12.1505C4.18947 12.2312 4.23843 12.3044 4.30015 12.3662C4.36187 12.4279 4.43514 12.4768 4.51579 12.5102C4.59643 12.5437 4.68286 12.5608 4.77015 12.5608C4.85744 12.5608 4.94387 12.5437 5.02451 12.5102C5.10515 12.4768 5.17843 12.4279 5.24015 12.3662L8.50015 9.10616L11.7601 12.3662C11.8219 12.4279 11.8951 12.4768 11.9758 12.5102C12.0564 12.5437 12.1429 12.5608 12.2301 12.5608C12.3174 12.5608 12.4039 12.5437 12.4845 12.5102C12.5652 12.4768 12.6384 12.4279 12.7001 12.3662C12.7619 12.3044 12.8108 12.2312 12.8442 12.1505C12.8776 12.0699 12.8948 11.9835 12.8948 11.8962C12.8948 11.8089 12.8776 11.7224 12.8442 11.6418C12.8108 11.5612 12.7619 11.4879 12.7001 11.4262L9.44015 8.16616L12.7001 4.90616C12.9535 4.65283 12.9535 4.22616 12.7001 3.97283Z"
                  fill="#333333"
                />
              </svg>
            </div>
            <div className="flex items-center flex-col justify-center border-b border-b-[#E4E4E4] pb-6">
              <p className="text-center text-[1.125rem] font-medium text-[#333333] mb-2">
                Account Details
              </p>
              <p
                className={`${
                  selectedAccount?.status === "Eligible"
                    ? "bg-[#72E52C1A] text-[#72E52C]"
                    : selectedAccount?.status === "Unprocessed"
                    ? "bg-[#EDBB071A] text-[#EDBB07]"
                    : "bg-[#B410071A] text-[#B41007]"
                } text-[0.875rem] font-medium rounded-[200px] px-2 w-max text-center py-1`}
              >
                {selectedAccount?.status}
              </p>
            </div>
            <div className="space-y-6 border border-[#E4E4E4] rounded-lg p-6">
              <div className="w-full space-y-6">
                <div className="flex items-center justify-between flex-wrap gap-6 w-[100%]">
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">USER</p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {" "}
                      {selectedAccount?.fullname}
                      <p className="text-[#62646A99] text-[0.75rem] font-medium">
                        {selectedAccount?.user}
                      </p>
                    </p>
                  </div>
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      ACCOUNT NO.
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {" "}
                      {selectedAccount?.account}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between flex-wrap gap-6 w-[100%]">
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">DATE</p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {" "}
                      {selectedAccount?.date}
                    </p>
                  </div>
                  <div>
                    <p className="text-[0.75rem] text-[#62646A99] mb-1">
                      COMMENT
                    </p>
                    <p className="text-[0.875rem] text-[#333333] font-medium">
                      {selectedAccount?.comment || "No Comment"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <button
              disabled={
                selectedAccount?.status === "Eligible" ||
                selectedAccount?.status === "Ineligible"
              }
              onClick={() => {
                setStatus("Eligible");
                setOpenComment(true);
              }}
              className="bg-[#3EB211] text-white rounded-md py-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
            >
              {createLoading ? "Please wait..." : "Eligible"}
            </button>
            <button
              disabled={
                selectedAccount?.status === "Eligible" ||
                selectedAccount?.status === "Ineligible"
              }
              onClick={() => {
                setStatus("Ineligible");
                setOpenComment(true);
              }}
              className="bg-[#B41007] text-white rounded-md py-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
            >
              {createLoading ? "Please wait..." : "Ineligible"}
            </button>
          </DialogPanel>
        </div>
      </Dialog>
      <Dialog
        open={openComment}
        onClose={() => {
          setOpenComment(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/50 justify-center p-4">
          <DialogPanel className="rounded-lg font-poppins space-y-6 border bg-white p-6 w-[600px] max-h-[700px]">
            <div className="flex items-center justify-end">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="cursor-pointer"
                onClick={() => {
                  setOpenComment(false);
                }}
              >
                <path
                  d="M12.7001 3.97283C12.6385 3.91103 12.5652 3.862 12.4846 3.82854C12.4039 3.79509 12.3175 3.77787 12.2301 3.77787C12.1428 3.77787 12.0564 3.79509 11.9757 3.82854C11.8951 3.862 11.8218 3.91103 11.7601 3.97283L8.50015 7.22616L5.24015 3.96616C5.17843 3.90444 5.10515 3.85548 5.02451 3.82208C4.94387 3.78868 4.85744 3.77148 4.77015 3.77148C4.68286 3.77148 4.59643 3.78868 4.51579 3.82208C4.43514 3.85548 4.36187 3.90444 4.30015 3.96616C4.23843 4.02789 4.18947 4.10116 4.15606 4.1818C4.12266 4.26245 4.10547 4.34888 4.10547 4.43616C4.10547 4.52345 4.12266 4.60988 4.15606 4.69053C4.18947 4.77117 4.23843 4.84444 4.30015 4.90616L7.56015 8.16616L4.30015 11.4262C4.23843 11.4879 4.18947 11.5612 4.15606 11.6418C4.12266 11.7224 4.10547 11.8089 4.10547 11.8962C4.10547 11.9835 4.12266 12.0699 4.15606 12.1505C4.18947 12.2312 4.23843 12.3044 4.30015 12.3662C4.36187 12.4279 4.43514 12.4768 4.51579 12.5102C4.59643 12.5437 4.68286 12.5608 4.77015 12.5608C4.85744 12.5608 4.94387 12.5437 5.02451 12.5102C5.10515 12.4768 5.17843 12.4279 5.24015 12.3662L8.50015 9.10616L11.7601 12.3662C11.8219 12.4279 11.8951 12.4768 11.9758 12.5102C12.0564 12.5437 12.1429 12.5608 12.2301 12.5608C12.3174 12.5608 12.4039 12.5437 12.4845 12.5102C12.5652 12.4768 12.6384 12.4279 12.7001 12.3662C12.7619 12.3044 12.8108 12.2312 12.8442 12.1505C12.8776 12.0699 12.8948 11.9835 12.8948 11.8962C12.8948 11.8089 12.8776 11.7224 12.8442 11.6418C12.8108 11.5612 12.7619 11.4879 12.7001 11.4262L9.44015 8.16616L12.7001 4.90616C12.9535 4.65283 12.9535 4.22616 12.7001 3.97283Z"
                  fill="#333333"
                />
              </svg>
            </div>

            <div className="flex items-center rounded-lg flex-col justify-center">
              <p className="mb-3">Comment</p>
              <textarea
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                name=""
                id=""
                className="border border-[#E4E4E4] rounded-lg p-2 w-[100%] h-[150px]"
              ></textarea>
            </div>

            <button
              onClick={() => {
                updateReceipt();
              }}
              className="bg-[#3EB211] text-white rounded-md py-3 w-[100%]"
            >
              {actionLoading ? "Please wait..." : "Proceed"}
            </button>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}

export default ScaRequest;
