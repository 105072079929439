import React, { useEffect, useState } from "react";
import Sorting from "../assets/f7_sort-up (1).svg";
import More from "../assets/uiw_more (1).svg";
import convertDate from "../../utils/ConvertDate";
import axiosInstance from "../../axios";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Triangle } from "react-loader-spinner";
import { toast } from "react-toastify";
import useOnclickOutside from "react-cool-onclickoutside";

function PayoutTable({ data, setApproveType, formDetails, setFormDetails }) {
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [loading, setLoading] = useState(false);
  const [calculateId, setCalculateId] = useState();
  const ref = useOnclickOutside(() => {
    setOpenMenu(false);
  });

  useEffect(() => {
    function calculate() {
      setLoading(true);
      let formdata = new FormData();
      formdata.append("id", calculateId);

      axiosInstance
        .post(`/dashboard/calc-payout/`, formdata)
        .then((res) => {
          console.log(res);
          setFormDetails({ ...formDetails, amount: res.data.amount });
          setApproveType("form");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed to calculate payout", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }

    if (calculateId) {
      calculate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateId]);

  return (
    <div className="w-[100%] mt-[24px] text-[0.87rem]">
      <table className="w-[100%] hidden xl:table">
        <thead className="bg-[#F1F1F1] text-[0.8rem]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[11.1%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                User
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[11.1%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                A/C No.
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[11.1%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Type
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[11.1%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Amount
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[11.1%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Req Date
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[11.1%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Payment Date
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[11.1%] text-center">
              Status
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[11.1%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Reason
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[11.1%]"></td>
          </tr>
        </thead>
        <tbody>
          {data?.map((da) => (
            <tr key={da.id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.useracc?.full_name || "No Name"}
                <p className="text-[#62646A99] text-[0.75rem] font-medium">
                  {da.useracc?.user || "No Email"}
                </p>
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.useracc?.acc?.number}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.paytype}
              </td>
              <td className="py-[16px] text-[#333333] font-medium text-center font-poppins">
                {da.amount.length > 0 ? da.amount : 0}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {convertDate(da.date)}
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.payment_date || "No Date"}
              </td>
              <td className="py-[16px] font-medium font-poppins">
                <p
                  className={`${
                    da.status.toLowerCase() === "paid"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : da.status.toLowerCase() === "pending"
                      ? "bg-[#EDBB071A] text-[#EDBB07]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 w-max mx-auto text-center`}
                >
                  {da.status}
                </p>
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.reason || "No Reason"}
              </td>
              <td className="text-[#333333] font-medium font-poppins">
                <div
                  // onClick={() => {
                  //   setSelected(da);
                  //   setIsOpen(true);
                  // }}
                  className="py-[16px] relative flex items-center justify-center cursor-pointer"
                >
                  <img
                    onClick={() => {
                      setSelectedId(da.id);
                      setOpenMenu(!openMenu);
                    }}
                    src={More}
                    alt=""
                  />

                  {da.id === selectedId && openMenu && (
                    <div ref={ref} className="bg-white p-3 rounded-lg shadow-lg flex flex-col gap-3 absolute -left-[5rem] -bottom-[7rem] z-10">
                      <a
                        href={`https://dashboard.ft9ja.com/login?email=owenyakubu533@gmail.com&password=EHpDFzL5hKnMqq8&number=${da.useracc.acc.number}`}
                        target="_blank"
                        className="bg-[#3EB211] border border-[#3EB211] text-center whitespace-nowrap text-white rounded-md p-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
                        rel="noreferrer"
                      >
                        View Dashboard
                      </a>
                      <button
                        disabled={da.status !== "pending"}
                        onClick={() => {
                          // setApproveType("form");
                          setFormDetails({ ...formDetails, id: da.id });
                          setCalculateId(da.id);
                          setSelectedId();
                          setOpenMenu(false);
                        }}
                        className="bg-white border border-[#3EB211] disabled:border-gray-400 text-center whitespace-nowrap text-[#3EB211] rounded-md p-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
                      >
                        Calculate
                      </button>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="xl:hidden lg:mt-10 flex flex-col items-center gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins w-[100%] lg:w-[80%]"
          >
            <div
              // onClick={() => {
              //   setSelected(da);
              //   setIsOpen(true);
              // }}
              className="flex justify-end relative cursor-pointer"
            >
              <img
                onClick={() => {
                  setSelectedId(da.id);
                  setOpenMenu(!openMenu);
                }}
                src={More}
                alt=""
              />

              {da.id === selectedId && openMenu && (
                <div ref={ref} className="bg-white p-3 rounded-lg shadow-lg flex flex-col gap-3 absolute right-0 top-3 z-10">
                  <a
                    href={`https://dashboard.ft9ja.com/login?email=owenyakubu533@gmail.com&password=EHpDFzL5hKnMqq8&number=${da.useracc.acc.number}`}
                    target="_blank"
                    className="bg-[#3EB211] border border-[#3EB211] text-center whitespace-nowrap text-white rounded-md p-3 w-[100%] disabled:border-gray-400 disabled:bg-gray-400 disabled:text-gray-900"
                    rel="noreferrer"
                  >
                    View Dashboard
                  </a>
                  <button
                    disabled={da.status !== "pending"}
                    onClick={() => {
                      // setApproveType("form");
                      setFormDetails({ ...formDetails, id: da.id });
                      setCalculateId(da.id);
                      setSelectedId();
                      setOpenMenu(false);
                    }}
                    className="bg-white border border-[#3EB211] text-center whitespace-nowrap text-[#3EB211] rounded-md p-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
                  >
                    Calculate
                  </button>
                </div>
              )}
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">USER</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.useracc?.full_name || "No Name"}
                  <p className="text-[#62646A99] text-[0.75rem] font-medium">
                    {da.useracc?.user || "No Email"}
                  </p>
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">TYPE</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.paytype}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">AMOUNT</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.amount.length > 0 ? da.amount : 0}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">REQ DATE</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {convertDate(da.date)}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  PAYMENT DATE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.payment_date || "No Date"}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">STATUS</p>
                <p
                  className={`${
                    da.status.toLowerCase() === "paid"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : da.status.toLowerCase() === "pending"
                      ? "bg-[#EDBB071A] text-[#EDBB07]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 w-max text-center py-1`}
                >
                  {da.status}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">REASON</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.reason || "No Reason"}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">A/C NO.</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.useracc?.acc?.number}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Dialog
        open={loading}
        onClose={() => {
          setLoading(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/50 justify-center p-4">
          <DialogPanel className="rounded-lg font-poppins space-y-6 border bg-white p-6 w-[600px] max-h-[700px]">
            <div className="flex items-center justify-end">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="cursor-pointer"
                onClick={() => {
                  setLoading(false);
                }}
              >
                <path
                  d="M12.7001 3.97283C12.6385 3.91103 12.5652 3.862 12.4846 3.82854C12.4039 3.79509 12.3175 3.77787 12.2301 3.77787C12.1428 3.77787 12.0564 3.79509 11.9757 3.82854C11.8951 3.862 11.8218 3.91103 11.7601 3.97283L8.50015 7.22616L5.24015 3.96616C5.17843 3.90444 5.10515 3.85548 5.02451 3.82208C4.94387 3.78868 4.85744 3.77148 4.77015 3.77148C4.68286 3.77148 4.59643 3.78868 4.51579 3.82208C4.43514 3.85548 4.36187 3.90444 4.30015 3.96616C4.23843 4.02789 4.18947 4.10116 4.15606 4.1818C4.12266 4.26245 4.10547 4.34888 4.10547 4.43616C4.10547 4.52345 4.12266 4.60988 4.15606 4.69053C4.18947 4.77117 4.23843 4.84444 4.30015 4.90616L7.56015 8.16616L4.30015 11.4262C4.23843 11.4879 4.18947 11.5612 4.15606 11.6418C4.12266 11.7224 4.10547 11.8089 4.10547 11.8962C4.10547 11.9835 4.12266 12.0699 4.15606 12.1505C4.18947 12.2312 4.23843 12.3044 4.30015 12.3662C4.36187 12.4279 4.43514 12.4768 4.51579 12.5102C4.59643 12.5437 4.68286 12.5608 4.77015 12.5608C4.85744 12.5608 4.94387 12.5437 5.02451 12.5102C5.10515 12.4768 5.17843 12.4279 5.24015 12.3662L8.50015 9.10616L11.7601 12.3662C11.8219 12.4279 11.8951 12.4768 11.9758 12.5102C12.0564 12.5437 12.1429 12.5608 12.2301 12.5608C12.3174 12.5608 12.4039 12.5437 12.4845 12.5102C12.5652 12.4768 12.6384 12.4279 12.7001 12.3662C12.7619 12.3044 12.8108 12.2312 12.8442 12.1505C12.8776 12.0699 12.8948 11.9835 12.8948 11.8962C12.8948 11.8089 12.8776 11.7224 12.8442 11.6418C12.8108 11.5612 12.7619 11.4879 12.7001 11.4262L9.44015 8.16616L12.7001 4.90616C12.9535 4.65283 12.9535 4.22616 12.7001 3.97283Z"
                  fill="#333333"
                />
              </svg>
            </div>

            <div className="space-y-4 flex items-center justify-center flex-col">
              <Triangle
                visible={true}
                height="80"
                width="80"
                color="#3EB211"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
              <p className="text-center">
                Calculating Payout Amount, Please wait...
              </p>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}

export default PayoutTable;
