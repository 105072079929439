import React from "react";
import Sorting from "../assets/f7_sort-up (1).svg";
import More from "../assets/uiw_more (1).svg";

function FcaTable({ data, setSelectedAccount, setOpenSelected }) {
  return (
    <div className="w-[100%] mt-[24px]">
      <table className="w-[100%] hidden lg:table">
        <thead className="bg-[#F1F1F1] text-[0.875rem]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                User
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Account Type
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                A/C ID
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                A/C No.
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                A/C Size.
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%] text-center">
              Status
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]"></td>
          </tr>
        </thead>
        <tbody className="text-[0.875rem]">
          {data?.map((da) => (
            <tr key={da._id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.full_name}
                <p className="text-[#62646A99] text-[0.75rem] font-medium">
                  {da.user}
                </p>
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.acc.typeofaccount}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.acc.id}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.acc.number}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.acc.acc_size}
              </td>
              <td className="py-[16px] font-medium font-poppins flex items-center justify-center">
                <p
                  className={`${
                    !da.blown && !da.inactive
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 w-max text-center py-1`}
                >
                  {da.blown ? "Blown" : da.inactive ? "Inactive" : "Active "}
                </p>
              </td>
              <td className="text-[#333333] font-medium font-poppins">
                <div
                  onClick={() => {
                    setSelectedAccount(da);
                    setOpenSelected(true);
                  }}
                  className="py-[16px] flex items-center justify-center cursor-pointer"
                >
                  <img src={More} alt="" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="lg:hidden flex flex-col gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins"
          >
            <div
              onClick={() => {
                setSelectedAccount(da);
                setOpenSelected(true);
              }}
              className="flex justify-end cursor-pointer"
            >
              <img src={More} alt="" />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">USER</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.full_name}
                  <p className="text-[#62646A99] text-[0.75rem] font-medium">
                    {da.user}
                  </p>
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  ACCOUNT TYPE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.acc.typeofaccount}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">A/C ID</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.acc.id}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  ACCOUNT NUMBER
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.acc.number}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  ACCOUNT SIZE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.acc.acc_size}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">STATUS</p>
                {/* <p className="bg-[#72E52C1A] text-[#72E52C] text-[0.875rem] font-medium rounded-[200px] px-2 py-1">
                      ACTIVE
                    </p> */}
                <p
                  className={`${
                    !da.blown && !da.inactive
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 w-max text-center py-1`}
                >
                  {da.blown ? "Blown" : da.inactive ? "Inactive" : "Active "}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FcaTable;
