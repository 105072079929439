/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Sorting from "../assets/f7_sort-up (1).svg";
// import More from "../assets/uiw_more (1).svg";
import convertDate from "../../utils/ConvertDate";
import Delete from "../assets/basil_trash-outline.svg";
import Edit from "../assets/iconamoon_edit.svg";
// import convertDate from "../../utils/ConvertDate";

function MembersTable({ data, setOpenDelete, setOpenEdit, setSelectedMember }) {
  return (
    <div className="w-[100%] mt-[24px]">
      <table className="w-[100%] hidden lg:table">
        <thead className="bg-[#F1F1F1] text-[0.875rem]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[20%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                Name
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[20%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Role
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[20%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Date Added
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[20%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Last Active
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[20%]"></td>
          </tr>
        </thead>
        <tbody className="text-[0.875rem]">
          {data?.map((da) => (
            <tr key={da.user_id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.user}
                {/* <p className="text-[#62646A99] text-[0.75rem] font-medium">
                  {da.user}
                </p> */}
              </td>
              <td
                className={`py-[16px] text-[#333333] font-medium flex flex-col gap-3 items-center justify-center font-poppins text-center`}
              >
                {da.groups.map(na => <p
                  className={`${
                    na.group_name?.toLowerCase() === "super admin"
                      ? "border border-[#3EB211] text-[#3EB211]"
                      : na.group_name?.toLowerCase() ===
                        "payments admin"
                      ? "border border-[#0795B4] text-[#0795B4]"
                      : na.group_name?.toLowerCase() ===
                        "payout admin"
                      ? "border border-[#EA008C] text-[#EA008C]"
                      : na.group_name?.toLowerCase() ===
                        "affiliate admin"
                      ? "border border-[#8707B4] text-[#8707B4]"
                      : na.group_name?.toLowerCase() ===
                        "account admin"
                      ? "border border-[#4E1EE3] text-[#4E1EE3]"
                      : na.group_name?.toLowerCase() ===
                        "view-only super admin"
                      ? "border border-[#EDBB07] text-[#EDBB07]"
                      : "border border-[#B41007] text-[#B41007]"
                  } rounded-full w-max px-3`}
                >
                  {na.group_name}
                </p> )}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {convertDate(da.groups[0].added_at)}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {convertDate(da.last_activity)}
              </td>

              <td className="text-[#333333] font-medium font-poppins">
                <div
                  // onClick={() => {
                  //   setSelected(da);
                  //   setIsOpen(true);
                  // }}
                  className="py-[16px] flex gap-4 items-center justify-center cursor-pointer"
                >
                  <img
                    onClick={() => {
                       setSelectedMember({
                        id: da.user_id,
                        user: da.user,
                        name: da.groups[0].group_name,
                      });
                      setOpenDelete(true);
                    }}
                    src={Delete}
                    alt=""
                  />
                  <img
                    onClick={() => {
                      setSelectedMember({
                        id: da.user_id,
                        user: da.user,
                        name: da.groups[0].group_name,
                      });
                      setOpenEdit(true);
                    }}
                    src={Edit}
                    alt=""
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="lg:hidden flex flex-col gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins"
          >
            <div
              // onClick={() => {
              //   setSelected(da);
              //   setIsOpen(true);
              // }}
              className="flex gap-4 justify-end cursor-pointer"
            >
              <img
                onClick={() => {
                   setSelectedMember({
                        id: da.user_id,
                        user: da.user,
                        name: da.groups[0].group_name,
                      });
                  setOpenDelete(true);
                }}
                src={Delete}
                alt=""
              />
              <img
                onClick={() => {
                   setSelectedMember({
                        id: da.user_id,
                        user: da.user,
                        name: da.groups[0].group_name,
                      });
                  setOpenEdit(true);
                }}
                src={Edit}
                alt=""
              />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">USER</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.user}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">ROLE</p>
                {/* <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.groups[0].group_name}
                </p> */}
                {da.groups.map(na => <p
                  className={`text-[0.875rem] font-medium ${
                    na.group_name?.toLowerCase() === "super admin"
                      ? "border border-[#3EB211] text-[#3EB211]"
                      : na.group_name?.toLowerCase() ===
                        "payments admin"
                      ? "border border-[#0795B4] text-[#0795B4]"
                      : na.group_name?.toLowerCase() ===
                        "payout admin"
                      ? "border border-[#EA008C] text-[#EA008C]"
                      : na.group_name?.toLowerCase() ===
                        "affiliate admin"
                      ? "border border-[#8707B4] text-[#8707B4]"
                      : na.group_name?.toLowerCase() ===
                        "account admin"
                      ? "border border-[#4E1EE3] text-[#4E1EE3]"
                      : na.group_name?.toLowerCase() ===
                        "view-only super admin"
                      ? "border border-[#EDBB07] text-[#EDBB07]"
                      : "border border-[#B41007] text-[#B41007]"
                  } rounded-full w-max px-3`}
                >
                  {na.group_name}
                </p> )}
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  DATE ADDED
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {convertDate(da.groups[0].added_at)}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  LAST ACTIVE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {convertDate(da.last_activity)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MembersTable;
