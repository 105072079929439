import DashboardBlack from "../components/assets/mage_dashboard-chart-fill (2).svg";
import Dashboard from "../components/assets/mage_dashboard-chart-fill (1).svg";
import UsersBlack from "../components/assets/heroicons_user-20-solid (3).svg";
import Users from "../components/assets/heroicons_user-20-solid (2).svg";
import Accounts from "../components/assets/mdi_briefcase-account.svg";
import AccountsBlack from "../components/assets/mdi_briefcase-account (1).svg";
import AccountsReqBlack from "../components/assets/MaterialSymbolsAccountTree (1).svg";
import AccountsReq from "../components/assets/MaterialSymbolsAccountTree.svg";
import ReceiptBlack from "../components/assets/ri_receipt-fill (1).svg";
import Receipt from "../components/assets/ri_receipt-fill.svg";
import Payouts from "../components/assets/fluent_payment-28-filled.svg";
import PayoutsBlack from "../components/assets/fluent_payment-28-filled (1).svg";
import DiscountsBlack from "../components/assets/teenyicons_discount-solid (1).svg";
import Discount from "../components/assets/teenyicons_discount-solid.svg";
import Teams from "../components/assets/ri_team-fill.svg";
import TeamsBlack from "../components/assets/ri_team-fill (1).svg";
import More from "../components/assets/mingcute_plus-fill.svg";
import MoreBlack from "../components/assets/mingcute_plus-fill (1).svg";
import ViolationsBlack from "../components/assets/tabler_message-report-filled.svg";
import Violations from "../components/assets/tabler_message-report-filled - Copy.svg";
import ChatBotBlack from "../components/assets/BxBxsBot (1).svg";
import ChatBot from "../components/assets/BxBxsBot.svg";

function FilterNavLinks(
  SuperAdmin,
  ViewOnlySuperAdmin,
  AccountAdmin,
  PaymentsAdmin,
  PayoutAdmin,
  AffiliateAdmin,
  ChatbotOnly
) {
  let newNav = [];

  if (AccountAdmin) {
    newNav.push({
      id: 3,
      title: "Accounts",
      logo_black: AccountsBlack,
      logo: Accounts,
      link: "/accounts",
      sub: [
        {
          id: 1,
          title: "FCA",
          link: "/fca",
        },
        {
          id: 2,
          title: "SCA",
          link: "/sca",
        },
        {
          id: 3,
          title: "ASO",
          link: "/aso",
        },
        // {
        //   id: 4,
        //   title: "Verification Codes",
        //   link: "/codes",
        // },
      ],
    });
    newNav.push({
      id: 4,
      title: "Accounts Requests",
      logo_black: AccountsReqBlack,
      logo: AccountsReq,
      link: "/accounts",
      sub: [
        {
          id: 1,
          title: "Aso Request",
          link: "/aso-request",
        },
        {
          id: 2,
          title: "SCA Request",
          link: "/sca-request",
        },
        {
          id: 3,
          title: "Verification Request",
          link: "/verifi-request",
        },
        // {
        //   id: 4,
        //   title: "Verification Codes",
        //   link: "/codes",
        // },
      ],
    });
  }

  if (ChatbotOnly) {
    newNav.push({
      id: 11,
      title: "ChatBot",
      logo_black: ChatBotBlack,
      logo: ChatBot,
      link: "/chatbot",
      sub: [],
    });
  }

  if (PaymentsAdmin) {
    newNav.push({
      id: 5,
      title: "Receipts",
      logo_black: ReceiptBlack,
      logo: Receipt,
      link: "/receipts",
      sub: [
        {
          id: 1,
          title: "Transfer Receipts",
          link: "/transfer-receipts",
        },
        {
          id: 2,
          title: "Paystack Receipts",
          link: "/paystack-receipts",
        },
      ],
    });
  }
  if (PayoutAdmin) {
    newNav.push({
      id: 6,
      title: "Payouts",
      logo_black: PayoutsBlack,
      logo: Payouts,
      link: "/payouts",
      sub: [
        {
          id: 1,
          title: "Payout Requests",
          link: "/requests",
        },
        {
          id: 2,
          title: "Referral Payout Requests",
          link: "/ref_req",
        },
      ],
    });
    newNav.push({
      id: 8,
      title: "Violations",
      logo_black: ViolationsBlack,
      logo: Violations,
      link: "/violations",
      sub: [],
    });
  }

  //   if (AffiliateAdmin) {

  //   }
  const navs = [
    {
      id: 1,
      title: "Dashboard",
      logo_black: DashboardBlack,
      logo: Dashboard,
      link: "/home",
      sub: [],
    },
    {
      id: 2,
      title: "Users",
      logo_black: UsersBlack,
      logo: Users,
      link: "/users",
      sub: [],
    },
    {
      id: 3,
      title: "Accounts",
      logo_black: AccountsBlack,
      logo: Accounts,
      link: "/accounts",
      sub: [
        {
          id: 1,
          title: "FCA",
          link: "/fca",
        },
        {
          id: 2,
          title: "SCA",
          link: "/sca",
        },
        {
          id: 3,
          title: "ASO",
          link: "/aso",
        },
        // {
        //   id: 4,
        //   title: "Verification Codes",
        //   link: "/codes",
        // },
      ],
    },
    {
      id: 4,
      title: "Accounts Requests",
      logo_black: AccountsReqBlack,
      logo: AccountsReq,
      link: "/accounts",
      sub: [
        {
          id: 1,
          title: "Aso Request",
          link: "/aso-request",
        },
        {
          id: 2,
          title: "SCA Request",
          link: "/sca-request",
        },
        {
          id: 3,
          title: "Verification Request",
          link: "/verifi-request",
        },
        // {
        //   id: 4,
        //   title: "Verification Codes",
        //   link: "/codes",
        // },
      ],
    },
    {
      id: 5,
      title: "Receipts",
      logo_black: ReceiptBlack,
      logo: Receipt,
      link: "/receipts",
      sub: [
        {
          id: 1,
          title: "Transfer Receipts",
          link: "/transfer-receipts",
        },
        {
          id: 2,
          title: "Paystack Receipts",
          link: "/paystack-receipts",
        },
      ],
    },
    {
      id: 6,
      title: "Payouts",
      logo_black: PayoutsBlack,
      logo: Payouts,
      link: "/payouts",
      sub: [
        {
          id: 1,
          title: "Payout Requests",
          link: "/requests",
        },
        {
          id: 2,
          title: "Referral Payout Requests",
          link: "/ref_req",
        },
      ],
    },
    {
      id: 7,
      title: "Team",
      logo_black: TeamsBlack,
      logo: Teams,
      link: "/team",
      sub: [],
    },
    {
      id: 8,
      title: "Violations",
      logo_black: ViolationsBlack,
      logo: Violations,
      link: "/violations",
      sub: [],
    },
    {
      id: 9,
      title: "Discounts",
      logo_black: DiscountsBlack,
      logo: Discount,
      link: "/discounts",
      sub: [
        //   {
        //     id: 1,
        //     title: "All Activity",
        //     link: "/activity?type=all",
        //   },
        //   {
        //     id: 2,
        //     title: "Proof of Activity",
        //     link: "/activity?type=proof",
        //   },
      ],
    },

    {
      id: 10,
      title: "More",
      logo_black: MoreBlack,
      logo: More,
      link: "/more",
      sub: [
        {
          id: 1,
          title: "FAQ Feedbacks",
          link: "/feedbacks",
        },
        {
          id: 2,
          title: "Chatbot Feedbacks",
          link: "/chatbot_feedbacks",
        },
        {
          id: 3,
          title: "User Stats",
          link: "/stats",
        },
        {
          id: 4,
          title: "Servers",
          link: "/servers",
        },
      ],
    },
    {
      id: 11,
      title: "ChatBot",
      logo_black: ChatBotBlack,
      logo: ChatBot,
      link: "/chatbot",
      sub: [],
    },
  ];

  return SuperAdmin || ViewOnlySuperAdmin ? navs : newNav;
}

export default FilterNavLinks;
