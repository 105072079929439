import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";
import Chevron from "../components/assets/ep_arrow-up-bold.svg";
import NotifBell from "../components/assets/Group.svg";
import UserProfile from "../components/assets/line-md_account (1).svg";
import MobileMenu from "./assets/jam_menu (1).svg";
import FilterNavLinks from "../utils/FilterNavLinks";
import axiosInstance from "../axios";

function Topbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
    }
  }, [isOpen]);

  const [open, setOpen] = useState("");
  const [group, setGroup] = useState([]);
  // let groups = JSON.parse(localStorage.getItem("groups"));
  let SuperAdmin = group?.includes("Super Admin");
  let ViewOnlySuperAdmin = group?.includes("View-only Super Admin");
  let AccountAdmin = group?.includes("Account Admin");
  let PaymentsAdmin = group?.includes("Payment Admin");
  let PayoutAdmin = group?.includes("Payout Admin");
  let AffiliateAdmin = group?.includes("Affiliate Admin");
  let ChatbotOnly = group?.includes("Chatbot-only");
  // console.log(groups.includes("Account Admin"))

  // console.log(SuperAdmin);
  // console.log(ViewOnlySuperAdmin);
  // console.log(AccountAdmin);
  // console.log(PaymentsAdmin);
  // console.log(PayoutAdmin);
  // console.log(AffiliateAdmin);
  // console.log(ChatbotOnly);
  const navs = FilterNavLinks(
    SuperAdmin,
    ViewOnlySuperAdmin,
    AccountAdmin,
    PaymentsAdmin,
    PayoutAdmin,
    AffiliateAdmin,
    ChatbotOnly
  );

  useEffect(() => {
    axiosInstance
      .get("/dashboard/user-groups/")
      .then((res) => {
        console.log(res);
        localStorage.setItem("email", res.data.user);
        localStorage.setItem("groups", JSON.stringify(res.data.groups));
        setGroup(res.data.groups);
        // setDetails({ email: "", password: "" });
        // setLoading(false);
        // navigate("/home");
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between lg:hidden px-[16px] py-[3px] mb-[19px]">
        <img
          className="w-[90px] h-[40px]"
          src="https://res.cloudinary.com/ddlupbcws/image/upload/q_auto/v1680791936/logo_2_ft9ja_1_fsh9iz.webp"
          alt=""
        />
        <img onClick={() => setIsOpen(true)} src={MobileMenu} alt="" />
      </div>
      <div className="flex items-center justify-between px-[32px] py-[18px] border-y border-y-[#E4E4E4]">
        <h1 className="text-[2rem] font-semibold font-poppins">
          {window.location.pathname === "/users"
            ? "Users"
            : window.location.pathname === "/accounts"
            ? "Accounts"
            : window.location.pathname === "/receipts"
            ? "Receipts"
            : window.location.pathname === "/payouts"
            ? "Payouts"
            : window.location.pathname === "/team"
            ? "Team"
            : window.location.pathname === "/violations"
            ? "Violations"
            : window.location.pathname === "/discounts"
            ? "Discounts"
            : window.location.pathname === "/feedbacks"
            ? "More"
            : window.location.pathname === "/servers"
            ? "More"
            : window.location.pathname === "/stats"
            ? "More"
            : window.location.pathname === "/chatbot_feedbacks"
            ? "More"
            : window.location.pathname === "/sca"
            ? "SCA"
            : window.location.pathname === "/fca"
            ? "FCA"
            : window.location.pathname === "/aso"
            ? "ASO"
            : window.location.pathname === "/aso-request"
            ? "ASO Requests"
            : window.location.pathname === "/sca-request"
            ? "SCA Requests"
            : window.location.pathname === "/verifi-request"
            ? "Verification Requests"
            : window.location.pathname === "/transfer-receipts"
            ? "Receipts"
            : window.location.pathname === "/paystack-receipts"
            ? "Paystack Receipts"
            : window.location.pathname === "/codes"
            ? "Verification Codes"
            : window.location.pathname === "/verifications"
            ? "Verifications"
            : window.location.pathname === "/requests"
            ? "Payout Requests"
            : window.location.pathname === "/ref_req"
            ? "Referral Payout Requests"
            : window.location.pathname === "/chatbot"
            ? "Support Chat"
            : "Dashboard"}
        </h1>
        <div className="flex items-center gap-[32px]">
          <div className="bg-[#F1F1F1] p-[4px] rounded-[4px]">
            <img src={NotifBell} alt="" />
          </div>
          <div className="flex items-center gap-[8px]">
            <img src={UserProfile} alt="" />
            <p className="font-poppins hidden lg:block">
              {localStorage.getItem("email") || "No email"}
            </p>
            <img src={Chevron} alt="" />
          </div>
        </div>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-start bg-black/30 justify-end">
          <Dialog.Panel
            className={`border bg-white p-5 rounded-l-[8px] font-poppins transform transition-transform duration-700 ${
              isAnimating ? "translate-y-full" : "translate-y-3"
            }`}
            onTransitionEnd={() => setIsAnimating(false)}
          >
            {/* Your content here */}
            <div className="w-[100%] px-[32px] py-[5px]">
              <img
                className="w-[90px] h-[40px]"
                src="https://res.cloudinary.com/ddlupbcws/image/upload/q_auto/v1680791936/logo_2_ft9ja_1_fsh9iz.webp"
                alt=""
              />
              <div className="mt-[16px] flex flex-col">
                {navs.map((nav) => {
                  return nav.title !== "Accounts" &&
                    nav.title !== "Payouts" &&
                    nav.title !== "More" &&
                    nav.title !== "Accounts Requests" &&
                    nav.title !== "Receipts" ? (
                    <Link
                      to={nav.link}
                      key={nav.id}
                      className="flex items-center gap-[8px] py-[10px] relative"
                    >
                      <img
                        src={
                          nav.link === window.location.pathname
                            ? nav.logo
                            : nav.logo_black
                        }
                        alt=""
                      />
                      <p
                        className={`${
                          nav.link === window.location.pathname
                            ? "text-[#3EB211]"
                            : "text-[#333333]"
                        } font-medium font-poppins`}
                      >
                        {nav.title}
                      </p>
                      {(nav.link === window.location.pathname ||
                        nav.sub[0]?.link === window.location.pathname ||
                        nav.sub[1]?.link === window.location.pathname ||
                        nav.sub[2]?.link === window.location.pathname ||
                        nav.sub[3]?.link === window.location.pathname) && (
                        <div className="bg-[#3EB211] w-[11px] h-[53px] absolute -left-[3.3rem] rounded-tr-[56px] rounded-br-[56px]"></div>
                      )}
                    </Link>
                  ) : (
                    <div
                      key={nav.id}
                      onClick={() => {
                        // window.location.href = `${nav.link}`;
                        if (open === nav.title) {
                          setOpen("");
                        } else {
                          setOpen(nav.title);
                        }
                      }}
                    >
                      <div className="flex items-center gap-[8px] py-[10px] justify-between relative cursor-pointer">
                        <div className="flex items-center gap-[8px]">
                          <img
                            src={
                              nav.link === window.location.pathname
                                ? nav.logo
                                : nav.logo_black
                            }
                            alt=""
                          />
                          <p
                            className={`${
                              nav.link === window.location.pathname
                                ? "text-[#3EB211]"
                                : "text-[#333333]"
                            } font-medium font-poppins`}
                          >
                            {nav.title}
                          </p>
                        </div>
                        <img src={Chevron} alt="" />
                        {(nav.link === window.location.pathname ||
                          nav.sub[0]?.link === window.location.pathname ||
                          nav.sub[1]?.link === window.location.pathname ||
                          nav.sub[2]?.link === window.location.pathname ||
                          nav.sub[3]?.link === window.location.pathname) && (
                          <div className="bg-[#3EB211] w-[11px] h-[53px] absolute -left-[3.3rem] rounded-tr-[56px] rounded-br-[56px]"></div>
                        )}
                      </div>
                      {open === nav.title && (
                        <div className="pl-7 flex flex-col">
                          {nav.sub.map((su) => (
                            <Link
                              onClick={() => setIsOpen(false)}
                              to={su.link}
                              key={su.id}
                            >
                              <p className="py-[7px]">{su.title}</p>
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}

export default Topbar;
