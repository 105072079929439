/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { CSVLink } from "react-csv";
import Search from "../components/assets/flowbite_search-outline (1).svg";
import { Triangle } from "react-loader-spinner";
import Chevron from "../components/assets/ep_arrow-up-bold.svg";
import axiosInstance from "../axios";
import RefPayTable from "../components/Tables/RefPayTable";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { Dialog, DialogPanel } from "@headlessui/react";

function RefPayReq() {
  const [data, setData] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [newUrl, setNewUrl] = useState(null);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterBy, setFilterBy] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [count, setCount] = useState(0);
  const [message, setMessage] = useState("");
  const [transferCode, setTransferCode] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [otp, setOtp] = useState("");
  const [openDeclineType, setOpenDeclineType] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [declining, setDeclining] = useState(false);
  const [listOfBanks, setListOfBanks] = useState();
  const [bankDetails, setBankDetails] = useState({
    bank: "",
    number: "",
  });
  const [formDetails, setFormDetails] = useState({
    amount: "",
    status: "Paid",
    date: "",
    reason: "",
  });

  const [customerDataForTransfer, setCustomerDataForTransfer] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const CSVdata = data;

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${newUrl || "dashboard/referral-payout-list/"}`)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setNextUrl(res.data.next);
        setPrevUrl(res.data.previous);
        setTotal(res.data.count);
        setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [newUrl, refresh]);

  const getListOfBanks = async () => {
    try {
      axios({
        method: "GET",
        url: "https://api.paystack.co/bank?currency=NGN",
        headers: {
          Authorization: `Bearer ${"sk_live_59c15f8ea814ea5dbe33a13b7a400ed9587aa033"}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log(response);
          setListOfBanks(response.data.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {}
  };

  // console.log(data);

  useEffect(() => {
    getListOfBanks();
  }, []);

  const getBankCode = async (customerData) => {
    setIsOpen(true);
    // setApproveType("");
    setMessage("Making Payment, Please Wait...");
    const filterBank = listOfBanks?.filter(
      (bank) =>
        bank.name === bankDetails.bank || bank.name.includes(bankDetails.name)
    );

    console.log(filterBank);
    // console.log(customerData);
    verifyDetails(bankDetails.number, filterBank);
  };

  const verifyDetails = async (
    customerDataForVerification,
    filterBankForVerification
  ) => {
    setMessage("Verifying Account Details, Please Wait...");
    // const account_number = parseInt(customerDataForVerification.Account_Number)
    const account_number = customerDataForVerification;
    console.log(account_number);
    console.log(filterBankForVerification);

    try {
      axios({
        method: "GET",
        url: `https://api.paystack.co/bank/resolve?account_number=${account_number}&bank_code=${filterBankForVerification[0].code}`,
        headers: {
          Authorization: `Bearer ${"sk_live_59c15f8ea814ea5dbe33a13b7a400ed9587aa033"}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log(response.data.data.account_name);
          setMessage(response.data.message);
          createRecipient(
            customerDataForVerification,
            filterBankForVerification,
            response.data.data.account_name
          );
        })
        .catch((error) => {
          console.error(error);
          setMessage(
            "Failed to verify that this account exists, please check the account details and try again"
          );
        });
    } catch (error) {}
  };

  const createRecipient = async (
    customerDataForRecipientCreation,
    customerBankCodeForRecipientCreation,
    accountNameForRecipientCreation
  ) => {
    setMessage("Creating Recipient, Please Wait...");
    try {
      axios({
        method: "POST",
        url: "https://api.paystack.co/transferrecipient",
        headers: {
          Authorization: `Bearer ${"sk_live_59c15f8ea814ea5dbe33a13b7a400ed9587aa033"}`,
          "Content-Type": "application/json",
        },
        data: {
          type: "nuban",
          name: accountNameForRecipientCreation,
          account_number: customerDataForRecipientCreation,
          bank_code: customerBankCodeForRecipientCreation[0]?.code,
          currency: "NGN",
        },
      })
        .then((response) => {
          console.log(response.data.data.recipient_code);
          setMessage(response.data.message);
          transferMoney(
            customerDataForRecipientCreation,
            response.data.data.recipient_code
          );
        })
        .catch((error) => {
          console.error(error);
          setMessage(
            "Couldn't create a recipient for this payout, please check the account details and try again or refresh the page and try again"
          );
        });
    } catch (error) {}
  };

  const transferMoney = async (
    customerDataForTransfer,
    recipientCodeForTransfer
  ) => {
    // console.log(i);
    setMessage("Initiating Transfer, Please Wait...");
    const id = uuidv4();
    const headers = {
      Authorization: `Bearer ${"sk_live_59c15f8ea814ea5dbe33a13b7a400ed9587aa033"}`,
      "Content-Type": "application/json",
    };

    const url = "https://api.paystack.co/transfer";

    const data = JSON.stringify({
      source: "balance",
      amount: formDetails.amount * 100,
      reference: id,
      recipient: recipientCodeForTransfer,
      reason: customerDataForTransfer.Type,
    });

    try {
      axios
        .post(url, data, { headers })
        .then((response) => {
          console.log(response);
          setTransferCode(response.data.data.transfer_code);
          setCustomerDataForTransfer(customerDataForTransfer);
          setMessage(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          setMessage(error.response.data.message);
          // alert(error.response.data.message);
        });
    } catch (error) {}
  };

  useEffect(() => {
    // setLoading(true);
    if (formDetails.id) {
      axiosInstance
        .get(`/api/payout-details/${formDetails.id}/`)
        .then((res) => {
          console.log(res);
          setBankDetails({
            bank: res.data.bank_name,
            number: res.data.account_number,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDetails.id]);

  function approvePayment(params) {
    // const timestamp = Date.now();

    // // Create a Date object
    // const date = new Date(timestamp);

    // // Format the date to yyyy-mm-dd
    // const formattedDate = date.toISOString().split("T")[0];

    setMessage("Updating request status");
    let formdata = new FormData();
    formdata.append("id", formDetails?.id);
    formdata.append("status", "Paid");

    axiosInstance
      .post(`/dashboard/referral-payout-list/`, formdata)
      .then((res) => {
        // console.log(res);
        setRefresh(!refresh);
        setIsOpen(false);
        setBankDetails({
          bank: "",
          number: "",
        });
        // setApproveType("");
        setOpenForm(false);
        setFormDetails({
          amount: "",
          date: "",
          reason: "",
          status: "Paid",
        });
        // setOpenDelete(false);
        toast.success("Successfully updated payout status", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        // setSelectedUserAccounts([...res.data.accounts, ...res.data.scas]);
        // setNextUrl(res.data.next);
        // setPrevUrl(res.data.previous);
        // setTotal(res.data.count);
        // setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  function declinePayout() {
    setIsOpen(true);
    setDeclining(true);
    const timestamp = Date.now();

    // Create a Date object
    const date = new Date(timestamp);

    // Format the date to yyyy-mm-dd
    const formattedDate = date.toISOString().split("T")[0];

    setMessage("Updating request status");
    let formdata = new FormData();
    formdata.append("id", formDetails?.id);
    formdata.append("status", "Duplicate");
    formdata.append("comment", "Duplicate entry");

    axiosInstance
      .post(`/dashboard/referral-payout-list/`, formdata)
      .then((res) => {
        // console.log(res);
        setRefresh(!refresh);
        setIsOpen(false);
        setBankDetails({
          bank: "",
          number: "",
        });
        // setApproveType("");
        setOpenForm(false);
        setFormDetails({
          amount: "",
          date: "",
          reason: "",
          status: "Paid",
        });
        setDeclining(false);
        // setOpenDelete(false);
        toast.success("Successfully updated payout status", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        // setSelectedUserAccounts([...res.data.accounts, ...res.data.scas]);
        // setNextUrl(res.data.next);
        // setPrevUrl(res.data.previous);
        // setTotal(res.data.count);
        // setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
        setDeclining(false);
        toast.error(err.response.data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  const payWithOtp = () => {
    const headers = {
      Authorization: `Bearer ${"sk_live_59c15f8ea814ea5dbe33a13b7a400ed9587aa033"}`,
      "Content-Type": "application/json",
    };

    const data = JSON.stringify({
      transfer_code: transferCode,
      otp: otp,
    });

    setMessage("Processing OTP, Please Wait...");

    try {
      axios
        .post("https://api.paystack.co/transfer/finalize_transfer", data, {
          headers,
        })
        .then((response) => {
          console.log(response);
          approvePayment();
          setRefresh(!refresh);
          // setApproveType("");
          setMessage(response.data.message);
          setTransferCode("");
        })
        .catch((error) => {
          setMessage(error.response.data.message);
          // approvePayment();
        });
    } catch (error) {}
  };

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="w-[100%] lg:w-[82%]">
        <Topbar />
        <div className="lg:p-[32px] p-[16px] h-[88vh] overflow-y-scroll">
          <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
            <div className="flex justify-between px-[16px]">
              <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                Referral Payout Request List
              </h1>
              <div className="flex items-center gap-10 text-[0.875rem]">
                <CSVLink
                  data={CSVdata}
                  filename={"referrals_payout_request_data.csv"}
                >
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
                {/* <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    onChange={(e) => setFilterBy(e.target.value)}
                    type="text"
                    placeholder="Search"
                    className="px-3 outline-none font-poppins"
                  />
                </div> */}
                {/* <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                  Filter
                  <img src={Chevron} alt="" />
                </button> */}
                <div className="flex items-center gap-5 font-poppins font-medium">
                  {prevUrl && (
                    <button
                      onClick={() => {
                        setNewUrl(prevUrl);
                      }}
                      className="bg-white text-[#3EB211] border border-[#3EB211] py-2 px-4 rounded-lg"
                    >
                      Prev
                    </button>
                  )}
                  {/* <div>
                            Showing: {data?.length} users per page
                            <p>Total users: {total.toLocaleString()}</p>
                          </div> */}
                  {nextUrl && (
                    <button
                      onClick={() => {
                        setCount(count + 1);
                        setNewUrl(nextUrl);
                      }}
                      className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <RefPayTable
                data={data}
                setFormDetails={setFormDetails}
                formDetails={formDetails}
                getBankCode={getBankCode}
                declinePayout={declinePayout}
              />
            )}
          </div>

          <div className="lg:hidden">
            <div className="flex items-center justify-between">
              <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                Referral Payout Request List
              </h1>

              <CSVLink
                data={CSVdata}
                filename={"referrals_payout_request_data.csv"}
              >
                <button className="text-[#3EB211] font-poppins font-medium">
                  Export CSV
                </button>
              </CSVLink>
            </div>
            <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
              <div className="flex items-center justify-between">
                {/* <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    onChange={(e) => setFilterBy(e.target.value)}
                    type="text"
                    placeholder="Search"
                    className="px-3 outline-none font-poppins"
                  />
                </div> */}
                {/* <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                  Filter
                  <img src={Chevron} alt="" />
                </button> */}
              </div>
              <div className="flex items-center gap-5 font-poppins font-medium">
                {prevUrl && (
                  <button
                    onClick={() => {
                      setNewUrl(prevUrl);
                    }}
                    className="bg-white text-[#3EB211] border border-[#3EB211] py-2 px-4 rounded-lg"
                  >
                    Prev
                  </button>
                )}
                {nextUrl && (
                  <button
                    onClick={() => {
                      setCount(count + 1);
                      setNewUrl(nextUrl);
                    }}
                    className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <RefPayTable
                data={data}
                setFormDetails={setFormDetails}
                formDetails={formDetails}
                getBankCode={getBankCode}
                declinePayout={declinePayout}
              />
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/50 justify-center p-4">
          <DialogPanel className="rounded-lg font-poppins space-y-6 border bg-white p-6 w-[600px] max-h-[700px]">
            <div className="flex items-center justify-end">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="cursor-pointer"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <path
                  d="M12.7001 3.97283C12.6385 3.91103 12.5652 3.862 12.4846 3.82854C12.4039 3.79509 12.3175 3.77787 12.2301 3.77787C12.1428 3.77787 12.0564 3.79509 11.9757 3.82854C11.8951 3.862 11.8218 3.91103 11.7601 3.97283L8.50015 7.22616L5.24015 3.96616C5.17843 3.90444 5.10515 3.85548 5.02451 3.82208C4.94387 3.78868 4.85744 3.77148 4.77015 3.77148C4.68286 3.77148 4.59643 3.78868 4.51579 3.82208C4.43514 3.85548 4.36187 3.90444 4.30015 3.96616C4.23843 4.02789 4.18947 4.10116 4.15606 4.1818C4.12266 4.26245 4.10547 4.34888 4.10547 4.43616C4.10547 4.52345 4.12266 4.60988 4.15606 4.69053C4.18947 4.77117 4.23843 4.84444 4.30015 4.90616L7.56015 8.16616L4.30015 11.4262C4.23843 11.4879 4.18947 11.5612 4.15606 11.6418C4.12266 11.7224 4.10547 11.8089 4.10547 11.8962C4.10547 11.9835 4.12266 12.0699 4.15606 12.1505C4.18947 12.2312 4.23843 12.3044 4.30015 12.3662C4.36187 12.4279 4.43514 12.4768 4.51579 12.5102C4.59643 12.5437 4.68286 12.5608 4.77015 12.5608C4.85744 12.5608 4.94387 12.5437 5.02451 12.5102C5.10515 12.4768 5.17843 12.4279 5.24015 12.3662L8.50015 9.10616L11.7601 12.3662C11.8219 12.4279 11.8951 12.4768 11.9758 12.5102C12.0564 12.5437 12.1429 12.5608 12.2301 12.5608C12.3174 12.5608 12.4039 12.5437 12.4845 12.5102C12.5652 12.4768 12.6384 12.4279 12.7001 12.3662C12.7619 12.3044 12.8108 12.2312 12.8442 12.1505C12.8776 12.0699 12.8948 11.9835 12.8948 11.8962C12.8948 11.8089 12.8776 11.7224 12.8442 11.6418C12.8108 11.5612 12.7619 11.4879 12.7001 11.4262L9.44015 8.16616L12.7001 4.90616C12.9535 4.65283 12.9535 4.22616 12.7001 3.97283Z"
                  fill="#333333"
                />
              </svg>
            </div>

            <div className="space-y-4 flex items-center justify-center flex-col">
              <Triangle
                visible={true}
                height="80"
                width="80"
                color="#3EB211"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
              <p>{declining ? "Please wait..." : message}</p>

              {transferCode !== "" && (
                <div className="flex items-center gap-2 mt-3">
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    onChange={(e) => setOtp(e.target.value)}
                    value={otp}
                    className="border outline-none border-black rounded-lg px-2 py-1"
                  />
                  <button
                    className="text-white bg-[#359602] text-lg py-0.5 px-2 rounded-lg disabled:bg-gray-500"
                    disabled={otp.length < 6}
                    onClick={payWithOtp}
                  >
                    Pay with OTP
                  </button>
                </div>
              )}
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}

export default RefPayReq;
