/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import FaqFeedTable from "../components/Tables/FaqFeedTable";
import axiosInstance from "../axios";
import { Triangle } from "react-loader-spinner";

function FaqFeed() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [newUrl, setNewUrl] = useState(null);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${newUrl || "dashboard/faq/"}`)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setNextUrl(res.data.next);
        setPrevUrl(res.data.previous);
        setTotal(res.data.count);
        setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [newUrl]);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="lg:w-[82%] w-[100%]">
        <Topbar />
        <div className="p-8">
          <div className="border border-[#E4E4E4] rounded-lg font-poppins p-6">
            <h1 className="text-center text-[1.125rem] font-medium text-[#333333] mb-6">
              FAQ Feedbacks
            </h1>
            {loading ? (
              <div className="w-[100%] h-[50vh] flex items-center justify-center">
                <Triangle
                  visible={true}
                  height="80"
                  width="80"
                  color="#3EB211"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <div className="border border-[#E4E4E4] rounded-lg">
                <FaqFeedTable data={data} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqFeed;
