/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Sorting from "../assets/f7_sort-up (1).svg";
import More from "../assets/uiw_more (1).svg";
import convertDate from "../../utils/ConvertDate";
import useOnclickOutside from "react-cool-onclickoutside";

function RefPayTable({ data, setFormDetails, getBankCode, declinePayout }) {
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [loading, setLoading] = useState(false);
  const [calculateId, setCalculateId] = useState();
  const ref = useOnclickOutside(() => {
    setOpenMenu(false);
  });
  return (
    <div className="w-[100%] mt-[24px] text-[0.87rem]">
      <table className="w-[100%] hidden xl:table">
        <thead className="bg-[#F1F1F1] text-[0.8rem]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[20%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                User
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[20%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Amount
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[20%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Date
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[20%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Status
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[20%]"></td>
          </tr>
        </thead>
        <tbody>
          {data?.map((da) => (
            <tr key={da._id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium font-poppins">
                {da.user.firstname}
                <p className="text-[#62646A99] text-[0.75rem] font-medium">
                  {da.user.email}
                </p>
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {da.amount || 0}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {convertDate(da.created)}
              </td>
              <td className="py-[16px] font-medium font-poppins">
                <p
                  className={`${
                    da.status.toLowerCase() === "paid"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : da.status.toLowerCase() === "unpaid"
                      ? "bg-[#EDBB071A] text-[#EDBB07]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 w-max mx-auto text-center`}
                >
                  {da.status}
                </p>
              </td>
              <td className="text-[#333333] font-medium font-poppins">
                <div
                  // onClick={() => {
                  //   setSelected(da);
                  //   setIsOpen(true);
                  // }}
                  className="py-[16px] flex items-center relative justify-center cursor-pointer"
                >
                  <img
                    onClick={() => {
                      setSelectedId(da.id);
                      setOpenMenu(!openMenu);
                      setFormDetails(da);
                    }}
                    src={More}
                    alt=""
                  />
                  {da.id === selectedId && openMenu && (
                    <div ref={ref} className="bg-white p-3 rounded-lg shadow-lg w-[15rem] flex flex-col gap-3 absolute right-20 top-[2rem] z-10">
                      <button
                        disabled={da.status.toLowerCase() !== "unpaid"}
                        onClick={() => {
                          // setApproveType("form");
                          // setFormDetails(da);
                          // setCalculateId(da.id);
                          // setSelectedId();
                          getBankCode();
                          setOpenMenu(false);
                        }}
                        className="bg-[#3EB211] border border-[#3EB211] disabled:border-gray-400 text-center whitespace-nowrap text-white rounded-md p-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
                      >
                        Pay
                      </button>
                      <button
                        disabled={da.status.toLowerCase() !== "unpaid"}
                        onClick={() => {
                          // setApproveType("form");
                          // setFormDetails(da);
                          // setCalculateId(da.id);
                          // setSelectedId();
                          declinePayout();
                          setOpenMenu(false);
                        }}
                        className="bg-[#B41007] border border-[#B41007] disabled:border-gray-400 text-center whitespace-nowrap text-white rounded-md p-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
                      >
                        Duplicate
                      </button>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="xl:hidden lg:mt-10 flex flex-col items-center gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins w-[100%] lg:w-[80%]"
          >
            <div
              // onClick={() => {
              //   setSelected(da);
              //   setIsOpen(true);
              // }}
              className="flex relative justify-end cursor-pointer"
            >
              <img
                onClick={() => {
                  setSelectedId(da.id);
                  setOpenMenu(!openMenu);
                  setFormDetails(da);
                }}
                src={More}
                alt=""
              />
              {da.id === selectedId && openMenu && (
                <div ref={ref} className="bg-white p-3 rounded-lg shadow-lg w-[15rem] flex flex-col gap-3 absolute right-0 top-3 z-10">
                  <button
                    disabled={da.status.toLowerCase() !== "unpaid"}
                    onClick={() => {
                      // setApproveType("form");
                      // setFormDetails(da);
                      // setCalculateId(da.id);
                      // setSelectedId();
                      getBankCode();
                      setOpenMenu(false);
                    }}
                    className="bg-[#3EB211] border border-[#3EB211] text-center whitespace-nowrap text-white rounded-md p-3 w-[100%] disabled:bg-gray-400 disabled:border-gray-400 disabled:text-gray-900"
                  >
                    Pay
                  </button>
                  <button
                    disabled={da.status.toLowerCase() !== "unpaid"}
                    onClick={() => {
                      // setApproveType("form");
                      // setFormDetails(da);
                      // setCalculateId(da.id);
                      // setSelectedId();
                      declinePayout();
                      setOpenMenu(false);
                    }}
                    className="bg-[#B41007] border border-[#B41007] text-center whitespace-nowrap disabled:border-gray-400 text-white rounded-md p-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
                  >
                    Duplicate
                  </button>
                </div>
              )}
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">USER</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.user.firstname}
                  <p className="text-[#62646A99] text-[0.75rem] font-medium">
                    {da.user.email}
                  </p>
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">AMOUNT</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.amount || 0}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">DATE</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {convertDate(da.created)}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">STATUS</p>
                <p
                  className={`${
                    da.status.toLowerCase() === "paid"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : da.status.toLowerCase() === "unpaid"
                      ? "bg-[#EDBB071A] text-[#EDBB07]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 w-max text-center py-1`}
                >
                  {da.status}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RefPayTable;
