/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import Topbar from "../components/Topbar";
import Sidebar from "../components/Sidebar";
import axiosInstance from "../axios";
import Search from "../components/assets/flowbite_search-outline (1).svg";
import convertDate from "../utils/ConvertDate";
import { toast } from "react-toastify";
import truncateText from "../utils/TruncateText";
import moment from "moment";
import { Triangle } from "react-loader-spinner";
import { Dialog, DialogPanel } from "@headlessui/react";
import Sorting from "../components/assets/f7_sort-up (1).svg";

function ChatBot() {
  const [loading, setLoading] = useState(false);
  const [threads, setThreads] = useState([]);
  const [selectedChat, setSelectedChat] = useState();
  const [selectedAllChat, setSelectedAllChat] = useState();
  const [messages, setMessages] = useState([]);
  const [allMessagesChats, setAllMessagesChats] = useState([]);
  const [socket, setSocket] = useState(null);
  const [refresh, setRefresh] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [socketChats, setSocketChats] = useState(null);
  const [message, setMessage] = useState("");
  const scrollableRef = useRef(null);
  const [formMessages, setFormMessages] = useState([]);
  const [loadingFormMessages, setLoadingFormMessages] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [newUrl, setNewUrl] = useState(null);
  const [total, setTotal] = useState(null);
  const [allMessages, setAllMessages] = useState(false);
  console.log(messages);
  useEffect(() => {
    setLoadingFormMessages(true);

    axiosInstance
      .get(
        `${newUrl || "/dashboard/support/messages/admin-requests/?all=true"}`
      )
      .then((res) => {
        console.log(res);
        setLoadingFormMessages(false);
        setNextUrl(res.data.next);
        setPrevUrl(res.data.previous);
        setTotal(res.data.count);
        setFormMessages(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [newUrl]);

  useEffect(() => {
    setThreads([]);

    const token = localStorage.getItem("access_token");
    if (!token) {
      toast.error("No access token found. Please log in again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const socketUrlChats = `wss://maindashbe-june-b18731a0e161.herokuapp.com/ws/admin-support/?access_token=${token}`;

    const newSocketChats = new WebSocket(socketUrlChats);

    setSocketChats(newSocketChats);

    newSocketChats.onopen = (event) => {
      console.log("WebSocket chats connection established.");
    };

    newSocketChats.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("chats", data.message);
      setThreads(data.message);
      if (Array.isArray(data.message)) {
        setThreads(data.message);
      } else {
        setThreads([JSON.parse(data.message), ...threads]);
      }
    };

    newSocketChats.onerror = (error) => {
      console.error("WebSocket Chats error:", error);
      console.log("An error occurred in Chats. Please try again.");
      setLoading(false);
    };

    newSocketChats.onclose = (event) => {
      console.log("WebSocket Chats connection closed:", event);
      console.log(
        "WebSocket Chats connection closed. Please refresh the page."
      );
      setLoading(false);
      // toast.error(
      //   "WebSocket Chats connection closed. Please refresh the page.",
      //   {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //   }
      // );
    };

    return () => {
      if (newSocketChats) newSocketChats.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAllMessagesChats([]);
    const formatContent = (content) => {
      // Replace newlines with <br> except within code blocks
      let formattedContent = content.replace(/```([\s\S]*?)```/g, (match) => {
        return match.replace(/\n/g, "PLACEHOLDER_FOR_NEWLINE");
      });
      formattedContent = formattedContent.replace(/\n/g, "<br>");
      formattedContent = formattedContent.replace(
        /PLACEHOLDER_FOR_NEWLINE/g,
        "\n"
      );

      // Replace bold text
      formattedContent = formattedContent.replace(
        /\*\*(.*?)\*\*/g,
        "<strong>$1</strong>"
      );

      // Replace italic text
      formattedContent = formattedContent.replace(/\*(.*?)\*/g, "<em>$1</em>");

      // Replace inline code
      formattedContent = formattedContent.replace(
        /`([^`]+)`/g,
        '<code class="block bg-[#0d0d0d] text-white p-3 m-2">$1</code>'
      );

      // Replace code blocks
      formattedContent = formattedContent.replace(
        /```([\s\S]*?)```/g,
        "<pre><code>$1</code></pre>"
      );

      // Replace headings (H1 to H6)
      formattedContent = formattedContent.replace(
        /###### (.*?)<br>/g,
        '<h6 class="py-2">$1</h6>'
      );
      formattedContent = formattedContent.replace(
        /##### (.*?)<br>/g,
        '<h6 class="py-2">$1</h6>'
      );
      formattedContent = formattedContent.replace(
        /#### (.*?)<br>/g,
        '<h6 class="py-2">$1</h6>'
      );
      formattedContent = formattedContent.replace(
        /### (.*?)<br>/g,
        '<h6 class="py-2">$1</h6>'
      );
      formattedContent = formattedContent.replace(
        /## (.*?)<br>/g,
        '<h6 class="py-2">$1</h6>'
      );
      formattedContent = formattedContent.replace(
        /# (.*?)<br>/g,
        '<h6 class="py-2">$1</h6>'
      );

      // Replace ordered lists
      formattedContent = formattedContent.replace(
        /(\d+)\. (.*?)<br>/g,
        (match, p1, p2) => {
          return `<li>${p2}</li>`;
        }
      );

      // Wrap ordered list items in <ol>
      formattedContent = formattedContent.replace(
        /(<li>.*?<\/li>)+/g,
        (match) => {
          return `<ol>${match}</ol>`;
        }
      );

      // Replace unordered lists
      formattedContent = formattedContent.replace(
        /^\*\s(.*?)$/gm,
        "<li>$1</li>"
      );

      // Wrap unordered list items in <ul>
      formattedContent = formattedContent.replace(
        /(<li>.*?<\/li>)+/g,
        (match) => {
          return `<ul>${match}</ul>`;
        }
      );

      // Replace links
      formattedContent = formattedContent.replace(
        /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g,
        '<a class="text-blue-500 underline transition hover:text-blue-300" href="$2" target="_blank">$1</a>'
      );

      return formattedContent;
    };

    if (selectedAllChat) {
      axiosInstance
        .get(
          `/dashboard/support/messages/admin-requests/${selectedAllChat.id}`
        )
        .then((res) => {
          console.log(res);
          const newMessages = res.data.reverse().map((message, i) => {
            if (message.sender === "assistant" || message.sender === "admin") {
              return { ...message, content: formatContent(message.content) };
            } else if (message.sender === "user") {
              return { ...message, content: formatContent(message.content) };
            } else {
              return { ...message, content: formatContent(message.content) };
            }
          });
          setAllMessagesChats((prevMessages) => [...prevMessages, ...newMessages]);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAllChat]);

  useEffect(() => {
    setMessages([]);

    const token = localStorage.getItem("access_token");
    if (!token) {
      toast.error("No access token found. Please log in again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    // const isProduction = true;
    // const protocol = isProduction ? "wss" : "ws";
    // const socketUrl = `${protocol}://${BASE_URL.replace(
    //   /^http(s)?:\/\//,
    //   ""
    // )}/ws/support/admin/?user_id=${selectedChat.user_id}&thread_id=${
    //   selectedChat.thread_value
    // }&query_string=${token}`;
    const socketUrl = `wss://maindashbe-june-b18731a0e161.herokuapp.com/ws/admin-support/?thread_id=${selectedChat?.thread_value}&access_token=${token}`;

    const newSocket = new WebSocket(socketUrl);

    setSocket(newSocket);

    newSocket.onopen = (event) => {
      console.log("WebSocket connection established.");
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("msg", data.message);

      const formatContent = (content) => {
        // Replace newlines with <br> except within code blocks
        let formattedContent = content.replace(/```([\s\S]*?)```/g, (match) => {
          return match.replace(/\n/g, "PLACEHOLDER_FOR_NEWLINE");
        });
        formattedContent = formattedContent.replace(/\n/g, "<br>");
        formattedContent = formattedContent.replace(
          /PLACEHOLDER_FOR_NEWLINE/g,
          "\n"
        );

        // Replace bold text
        formattedContent = formattedContent.replace(
          /\*\*(.*?)\*\*/g,
          "<strong>$1</strong>"
        );

        // Replace italic text
        formattedContent = formattedContent.replace(
          /\*(.*?)\*/g,
          "<em>$1</em>"
        );

        // Replace inline code
        formattedContent = formattedContent.replace(
          /`([^`]+)`/g,
          '<code class="block bg-[#0d0d0d] text-white p-3 m-2">$1</code>'
        );

        // Replace code blocks
        formattedContent = formattedContent.replace(
          /```([\s\S]*?)```/g,
          "<pre><code>$1</code></pre>"
        );

        // Replace headings (H1 to H6)
        formattedContent = formattedContent.replace(
          /###### (.*?)<br>/g,
          '<h6 class="py-2">$1</h6>'
        );
        formattedContent = formattedContent.replace(
          /##### (.*?)<br>/g,
          '<h6 class="py-2">$1</h6>'
        );
        formattedContent = formattedContent.replace(
          /#### (.*?)<br>/g,
          '<h6 class="py-2">$1</h6>'
        );
        formattedContent = formattedContent.replace(
          /### (.*?)<br>/g,
          '<h6 class="py-2">$1</h6>'
        );
        formattedContent = formattedContent.replace(
          /## (.*?)<br>/g,
          '<h6 class="py-2">$1</h6>'
        );
        formattedContent = formattedContent.replace(
          /# (.*?)<br>/g,
          '<h6 class="py-2">$1</h6>'
        );

        // Replace ordered lists
        formattedContent = formattedContent.replace(
          /(\d+)\. (.*?)<br>/g,
          (match, p1, p2) => {
            return `<li>${p2}</li>`;
          }
        );

        // Wrap ordered list items in <ol>
        formattedContent = formattedContent.replace(
          /(<li>.*?<\/li>)+/g,
          (match) => {
            return `<ol>${match}</ol>`;
          }
        );

        // Replace unordered lists
        formattedContent = formattedContent.replace(
          /^\*\s(.*?)$/gm,
          "<li>$1</li>"
        );

        // Wrap unordered list items in <ul>
        formattedContent = formattedContent.replace(
          /(<li>.*?<\/li>)+/g,
          (match) => {
            return `<ul>${match}</ul>`;
          }
        );

        // Replace links
        formattedContent = formattedContent.replace(
          /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g,
          '<a class="text-blue-500 underline transition hover:text-blue-300" href="$2" target="_blank">$1</a>'
        );

        return formattedContent;
      };

      if (Array.isArray(data.message)) {
        const newMessages = data.message.map((message, i) => {
          if (message.sender === "assistant" || message.sender === "admin") {
            return { ...message, content: formatContent(message.content) };
          } else if (message.sender === "user") {
            return { ...message, content: formatContent(message.content) };
          } else {
            return { ...message, content: formatContent(message.content) };
          }
        });

        setMessages((prevMessages) => [...prevMessages, ...newMessages]);

        setLoading(false);
      } else {
        setMessages([...messages, JSON.parse(data.message)]);

        setLoading(false);
      }
    };

    newSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
      console.log("An error occurred. Please try again.");
      setLoading(false);
    };

    newSocket.onclose = (event) => {
      console.log("WebSocket connection closed:", event);
      console.log("WebSocket connection closed. Please refresh the page.");
      setLoading(false);
      // toast.error("WebSocket connection closed. Please refresh the page.", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    };

    return () => {
      if (newSocket) newSocket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChat, refresh]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!message) return;
    if (!socket || socket.readyState !== WebSocket.OPEN) {
      setRefresh(!refresh);
      return toast.error("Kindly hold on...");
    }
    if (socket) {
      socket.send(JSON.stringify({ message }));
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          content: message,
          sender: "admin",
          created: new Date(),
          fullname: "Admin",
          user_pics: "Admin",
        },
      ]);
      setMessage("");

      scrollToBottom(scrollableRef);
    }
  };

  useEffect(() => {
    scrollToBottom(scrollableRef);
  }, [messages, loading]); // Scroll to bottom on initial render

  const scrollToBottom = (ref) => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  };

  const isNewDay = (current, previous) => {
    if (!previous) return true;
    return !moment(current).isSame(previous, "day");
  };

  return (
    <div className="flex h-[100vh] font-poppins">
      <Sidebar />
      <div className="w-[100%] lg:w-[82%]">
        <Topbar />
        <div className="lg:px-[32px] p-[16px] h-[88vh] overflow-y-scroll relative">
          <div className="flex items-center justify-end mb-2">
            <button
              onClick={() => setAllMessages(!allMessages)}
              className="bg-[#3EB211] absolute z-[1000] top-[2.5rem] right-[3rem] border border-[#3EB211] text-white rounded-lg py-2 px-4"
            >
              {allMessages ? "View Requested Messages" : "View All Messages"}
            </button>
          </div>
          {!allMessages && (
            <div className="flex items-start w-[100%] border border-[#E4E4E4] rounded-lg h-[100%]">
              <div className="w-[30%] border-r border-r-[#E4E4E4] h-[100%] overflow-y-scroll p-3">
                <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    // onChange={(e) => setSearchTerm(e.target.value)}
                    // value={searchTerm}
                    type="text"
                    placeholder="Search conversations..."
                    className="px-3 outline-none font-poppins text-[0.875rem]"
                  />
                </div>
                <div className="flex flex-col gap-3 my-5 border-y border-y-[#E4E4E4]">
                  {threads?.map((thr) => (
                    <div
                      className="border-b border-b-[#E4E4E4] py-3 flex gap-3 hover:scale-95 cursor-pointer duration-500"
                      key={thr.id}
                      onClick={() => setSelectedChat(thr)}
                    >
                      <img
                        src={
                          thr.user_pic ||
                          "https://res.cloudinary.com/ddlupbcws/image/upload/q_10,f_auto/v1726224358/3151513_stu3do.webp"
                        }
                        alt=""
                        className="w-[50px] h-[50px] rounded-full"
                      />
                      <div className="text-[0.75rem] flex flex-col w-[70%]">
                        <p className="ml-auto text-[#62646A99]">
                          {convertDate(thr.created)}
                        </p>
                        <p className="font-semibold text-[#333333]">
                          {thr.fullname || "No Name"}
                        </p>
                        <p className="text-[#62646A99] pl-3">
                          {truncateText(
                            thr.content,
                            window.innerWidth < 1300 ? 15 : 30
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-[70%] h-[100%] relative">
                <div className="absolute z-10 top-0 bg-white w-[100%] flex items-center gap-3 border-b border-b-[#E4E4E4] p-3">
                  <img
                    src={
                      selectedChat?.user_pic ||
                      "https://res.cloudinary.com/ddlupbcws/image/upload/q_10,f_auto/v1726224358/3151513_stu3do.webp"
                    }
                    alt=""
                    className="w-[50px] h-[50px] rounded-full"
                  />
                  <p className="font-semibold text-[#333333]">
                    {selectedChat?.fullname || "No Name"}
                  </p>
                </div>
                <div
                  ref={scrollableRef}
                  className="h-[100%] bg-gray-100 w-[100%] py-[7rem] overflow-y-scroll"
                >
                  {" "}
                  {messages.map((msg, i) => {
                    const showDate = isNewDay(
                      msg.created,
                      messages[i - 1]?.created
                    );

                    return (
                      <>
                        {showDate && (
                          <div className="flex justify-center gap-2 mb-6">
                            <hr className="border-none bg-[#D8D8D8] h-[1px] w-[30vw] max-w-[270px]" />
                            <p className="text-[12px] opacity-60 text-center  whitespace-nowrap">
                              {moment(msg.created).format(
                                "MMMM Do [at] hh:mm A"
                              )}
                            </p>
                            <hr className="border-none bg-[#D8D8D8] h-[1px] w-[30vw] max-w-[270px]" />
                          </div>
                        )}
                        {msg.sender === "user" ? (
                          <div className="flex flex-col mb-6 " key={i}>
                            <div className="flex gap-4">
                              <span
                                className={`app__flex capitalize text-center text-white font-semibold w-6 h-6 min-w-6 min-h-6 rounded-full overflow-hidden ${
                                  msg.user_pics ? "bg-[#D8D8D8]" : "bg-pink"
                                }`}
                              >
                                {msg.user_pics ? (
                                  <img
                                    src={
                                      msg.user_pics ||
                                      "https://res.cloudinary.com/ddlupbcws/image/upload/q_10,f_auto/v1726224358/3151513_stu3do.webp"
                                    }
                                    alt="user avater"
                                    className="object-cover w-full h-full"
                                  />
                                ) : (
                                  msg.user_name?.split(" ")[0][0]
                                )}
                              </span>

                              {/* <img
                                                                                                                                                        className="w-6 h-6 rounded-full "
                                                                                                                                                        src={msg.sender === "assistant" ? "https://i.pinimg.com/564x/1e/71/2d/1e712d2318258083c0df91f9ec749b77.jpg" : msg.user_pics}
                                                                                                                                                        alt="chat avater"
                                                                                                                                                /> */}
                              <div className="min-w-[100px] max-w-[80%] w-fit text-[14px] bg-white font-medium p-2 rounded-[10px] rounded-tl-none group">
                                <p className="text-purple font-bold text-[14px] mb-2">
                                  {msg.fullname?.split(" ")[0] || "No Name"}
                                </p>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: msg.content,
                                  }}
                                ></p>
                              </div>
                            </div>

                            <p className="w-fit mt-2 text-[12px] font-medium opacity-60 ml-10">
                              {moment(msg.created).format("h:mm A")}
                            </p>

                            {/* <AnimatePresence exitBeforeEnter>
                                    {modalCount === i && (
                                      <motion.div
                                        className="p-1.5 lg:p-3 rounded border border-solid border-[#F2F2F2] text-[10px] mt-2"
                                        initial={{ opacity: "0" }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: "0" }}
                                        transition={{
                                          type: "spring",
                                          bounce: 0.3,
                                          duration: 0.4,
                                        }}
                                        ref={thumbsDownRef}
                                        key="thumbsDownRef"
                                      >
                                        <p className="opacity-60 text-xm mb-2">
                                          Tell us more:
                                        </p>

                                        <div className="flex gap-2.5 flex-wrap">
                                          {[
                                            "Inaccurate or incomplete",
                                            " Was off-topic",
                                            "Difficult to understand",
                                            "Not useful information",
                                            "No detail or examples",
                                            "Broken links or references",
                                            "Incorrect code",
                                            "Other",
                                          ].map((feedback, i) => (
                                            <button
                                              className="p-1.5 rounded border border-solid border-[#F2F2F2] transition hover:bg-[#F2F2F2] "
                                              onClick={() => {
                                                dispatch(
                                                  voteBotResponse({
                                                    vote: "downvote",
                                                    thread: msg.thread,
                                                    feedback,
                                                  })
                                                );
                                                setModalCount(null);
                                              }}
                                              key={i}
                                            >
                                              {feedback}
                                            </button>
                                          ))}
                                        </div>
                                      </motion.div>
                                    )}
                                  </AnimatePresence> */}
                          </div>
                        ) : (
                          <div className="flex flex-col mb-6 items-end" key={i}>
                            <div className="flex flex-row-reverse gap-4">
                              {msg.user_pics || msg.sender === "assistant" ? (
                                <img
                                  src={
                                    msg.sender === "assistant"
                                      ? "https://i.pinimg.com/564x/1e/71/2d/1e712d2318258083c0df91f9ec749b77.jpg"
                                      : msg.user_pics
                                      ? msg.user_pics
                                      : "https://res.cloudinary.com/ddlupbcws/image/upload/q_10,f_auto/v1726224358/3151513_stu3do.webp"
                                  }
                                  className="rounded-full w-6 h-6 min-w-6 min-h-6  object-cover"
                                  alt="user avater"
                                />
                              ) : (
                                <span className="app__flex w-6 h-6 min-w-6 min-h-6 rounded-full bg-purple text-white  uppercase">
                                  {msg.user_name?.split(" ")[0][0]}
                                </span>
                              )}
                              <div className="max-w-[80%] text-right  w-fit min-w-[100px] text-[14px] bg-[#e9fbe8] font-medium p-2 rounded-[10px] rounded-tr-none">
                                <p className="text-pink font-bold text-[14px] mb-2">
                                  {msg.sender === "assistant"
                                    ? "Chatbot"
                                    : `${
                                        msg.fullname?.split(" ")[0] || "No Name"
                                      } (Admin)`}
                                </p>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: msg.content,
                                  }}
                                  className="text-left"
                                ></p>
                              </div>
                            </div>
                            <p className="w-fit mt-2 text-[12px] font-medium opacity-60 mr-10 ">
                              {moment(msg.created).format("h:mm A")}
                            </p>
                          </div>
                        )}
                      </>
                    );
                  })}
                  {loading && (
                    <div className="flex flex-col mb-6 ">
                      <div className="flex gap-4">
                        <img
                          className="w-6 h-6 rounded-full "
                          src="https://i.pinimg.com/564x/1e/71/2d/1e712d2318258083c0df91f9ec749b77.jpg"
                          alt="chat avater"
                        />
                        <p className="max-w-[80%] w-fit text-[14px] bg-[#F2F2F2] font-medium p-2 rounded-[10px] rounded-tl-none">
                          <Triangle />
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="bg-gray-200 z-10 absolute bottom-0 w-[100%] flex items-center justify-between pl-3">
                  <input
                    type="text"
                    className="py-3 outline-none bg-inherit px-1.5 w-[95%]"
                    placeholder="Type here..."
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                  />
                  <button
                    onClick={handleSubmit}
                    className="bg-[#3EB211] rounded-lg py-3 px-6 text-white font-semibold"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}
          {allMessages && (
            <div className="flex items-start w-[100%] border border-[#E4E4E4] rounded-lg h-[100%]">
              <div className="w-[30%] border-r border-r-[#E4E4E4] h-[100%] overflow-y-scroll p-3">
                <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                  <img src={Search} alt="" />
                  <input
                    // onChange={(e) => setSearchTerm(e.target.value)}
                    // value={searchTerm}
                    type="text"
                    placeholder="Search conversations..."
                    className="px-3 outline-none font-poppins text-[0.875rem]"
                  />
                </div>
                <div className="flex items-center gap-5 font-poppins mt-3 font-medium">
                  {prevUrl && (
                    <button
                      onClick={() => {
                        setNewUrl(prevUrl);
                      }}
                      className="bg-white text-[#3EB211] border border-[#3EB211] py-2 px-4 rounded-lg"
                    >
                      Prev
                    </button>
                  )}
                  {nextUrl && (
                    <button
                      onClick={() => {
                        // setCount(count + 1);
                        setNewUrl(nextUrl);
                      }}
                      className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
                    >
                      Next
                    </button>
                  )}
                </div>
                <div className="flex flex-col gap-3 my-5 border-y border-y-[#E4E4E4]">
                  {formMessages?.map((thr) => (
                    <div
                      className="border-b border-b-[#E4E4E4] py-3 flex gap-3 hover:scale-95 cursor-pointer duration-500"
                      key={thr.id}
                      onClick={() => setSelectedAllChat(thr)}
                    >
                      <img
                        src={
                          thr.user_pic ||
                          "https://res.cloudinary.com/ddlupbcws/image/upload/q_10,f_auto/v1726224358/3151513_stu3do.webp"
                        }
                        alt=""
                        className="w-[50px] h-[50px] rounded-full"
                      />
                      <div className="text-[0.75rem] flex flex-col w-[70%]">
                        <p className="ml-auto text-[#62646A99]">
                          {convertDate(thr.created)}
                        </p>
                        <p className="font-semibold text-[#333333]">
                          {thr.email || "No Name"}
                        </p>
                        <p className="text-[#62646A99] pl-3">
                          {truncateText(
                            thr.content,
                            window.innerWidth < 1300 ? 15 : 30
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-[70%] h-[100%] relative">
                <div className="absolute z-10 top-0 bg-white w-[100%] flex items-center gap-3 border-b border-b-[#E4E4E4] p-3">
                  <img
                    src={
                      selectedChat?.user_pic ||
                      "https://res.cloudinary.com/ddlupbcws/image/upload/q_10,f_auto/v1726224358/3151513_stu3do.webp"
                    }
                    alt=""
                    className="w-[50px] h-[50px] rounded-full"
                  />
                  <p className="font-semibold text-[#333333]">
                    {selectedChat?.fullname || "No Name"}
                  </p>
                </div>
                <div
                  ref={scrollableRef}
                  className="h-[100%] bg-gray-100 w-[100%] py-[7rem] overflow-y-scroll"
                >
                  {" "}
                  {allMessagesChats.map((msg, i) => {
                    const showDate = isNewDay(
                      msg.created,
                      messages[i - 1]?.created
                    );

                    return (
                      <>
                        {showDate && (
                          <div className="flex justify-center gap-2 mb-6">
                            <hr className="border-none bg-[#D8D8D8] h-[1px] w-[30vw] max-w-[270px]" />
                            <p className="text-[12px] opacity-60 text-center  whitespace-nowrap">
                              {moment(msg.created).format(
                                "MMMM Do [at] hh:mm A"
                              )}
                            </p>
                            <hr className="border-none bg-[#D8D8D8] h-[1px] w-[30vw] max-w-[270px]" />
                          </div>
                        )}
                        {msg.sender === "user" ? (
                          <div className="flex flex-col mb-6 " key={i}>
                            <div className="flex gap-4">
                              <span
                                className={`app__flex capitalize text-center text-white font-semibold w-6 h-6 min-w-6 min-h-6 rounded-full overflow-hidden ${
                                  msg.user_pics ? "bg-[#D8D8D8]" : "bg-pink"
                                }`}
                              >
                                {msg.user_pics ? (
                                  <img
                                    src={
                                      msg.user_pics ||
                                      "https://res.cloudinary.com/ddlupbcws/image/upload/q_10,f_auto/v1726224358/3151513_stu3do.webp"
                                    }
                                    alt="user avater"
                                    className="object-cover w-full h-full"
                                  />
                                ) : (
                                  msg.name?.split(" ")[0][0]
                                )}
                              </span>

                              {/* <img
                                                                                                                                                        className="w-6 h-6 rounded-full "
                                                                                                                                                        src={msg.sender === "assistant" ? "https://i.pinimg.com/564x/1e/71/2d/1e712d2318258083c0df91f9ec749b77.jpg" : msg.user_pics}
                                                                                                                                                        alt="chat avater"
                                                                                                                                                /> */}
                              <div className="min-w-[100px] max-w-[80%] w-fit text-[14px] bg-white font-medium p-2 rounded-[10px] rounded-tl-none group">
                                <p className="text-purple font-bold text-[14px] mb-2">
                                  {msg.name?.split(" ")[0] || "No Name"}
                                </p>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: msg.content,
                                  }}
                                ></p>
                              </div>
                            </div>

                            <p className="w-fit mt-2 text-[12px] font-medium opacity-60 ml-10">
                              {moment(msg.created).format("h:mm A")}
                            </p>

                            {/* <AnimatePresence exitBeforeEnter>
                                    {modalCount === i && (
                                      <motion.div
                                        className="p-1.5 lg:p-3 rounded border border-solid border-[#F2F2F2] text-[10px] mt-2"
                                        initial={{ opacity: "0" }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: "0" }}
                                        transition={{
                                          type: "spring",
                                          bounce: 0.3,
                                          duration: 0.4,
                                        }}
                                        ref={thumbsDownRef}
                                        key="thumbsDownRef"
                                      >
                                        <p className="opacity-60 text-xm mb-2">
                                          Tell us more:
                                        </p>

                                        <div className="flex gap-2.5 flex-wrap">
                                          {[
                                            "Inaccurate or incomplete",
                                            " Was off-topic",
                                            "Difficult to understand",
                                            "Not useful information",
                                            "No detail or examples",
                                            "Broken links or references",
                                            "Incorrect code",
                                            "Other",
                                          ].map((feedback, i) => (
                                            <button
                                              className="p-1.5 rounded border border-solid border-[#F2F2F2] transition hover:bg-[#F2F2F2] "
                                              onClick={() => {
                                                dispatch(
                                                  voteBotResponse({
                                                    vote: "downvote",
                                                    thread: msg.thread,
                                                    feedback,
                                                  })
                                                );
                                                setModalCount(null);
                                              }}
                                              key={i}
                                            >
                                              {feedback}
                                            </button>
                                          ))}
                                        </div>
                                      </motion.div>
                                    )}
                                  </AnimatePresence> */}
                          </div>
                        ) : (
                          <div className="flex flex-col mb-6 items-end" key={i}>
                            <div className="flex flex-row-reverse gap-4">
                              {msg.user_pics || msg.sender === "assistant" ? (
                                <img
                                  src={
                                    msg.sender === "assistant"
                                      ? "https://i.pinimg.com/564x/1e/71/2d/1e712d2318258083c0df91f9ec749b77.jpg"
                                      : msg.user_pics
                                      ? msg.user_pics
                                      : "https://res.cloudinary.com/ddlupbcws/image/upload/q_10,f_auto/v1726224358/3151513_stu3do.webp"
                                  }
                                  className="rounded-full w-6 h-6 min-w-6 min-h-6  object-cover"
                                  alt="user avater"
                                />
                              ) : (
                                <span className="app__flex w-6 h-6 min-w-6 min-h-6 rounded-full bg-purple text-white  uppercase">
                                  {msg.name?.split(" ")[0][0]}
                                </span>
                              )}
                              <div className="max-w-[80%] text-right  w-fit min-w-[100px] text-[14px] bg-[#e9fbe8] font-medium p-2 rounded-[10px] rounded-tr-none">
                                <p className="text-pink font-bold text-[14px] mb-2">
                                  {msg.sender === "assistant"
                                    ? "Chatbot"
                                    : `${
                                        msg.name?.split(" ")[0] || "No Name"
                                      } (Admin)`}
                                </p>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: msg.content,
                                  }}
                                  className="text-left"
                                ></p>
                              </div>
                            </div>
                            <p className="w-fit mt-2 text-[12px] font-medium opacity-60 mr-10 ">
                              {moment(msg.created).format("h:mm A")}
                            </p>
                          </div>
                        )}
                      </>
                    );
                  })}
                  {loading && (
                    <div className="flex flex-col mb-6 ">
                      <div className="flex gap-4">
                        <img
                          className="w-6 h-6 rounded-full "
                          src="https://i.pinimg.com/564x/1e/71/2d/1e712d2318258083c0df91f9ec749b77.jpg"
                          alt="chat avater"
                        />
                        <p className="max-w-[80%] w-fit text-[14px] bg-[#F2F2F2] font-medium p-2 rounded-[10px] rounded-tl-none">
                          <Triangle />
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatBot;
