import React from "react";
import Sorting from "../assets/f7_sort-up (1).svg";
import More from "../assets/uiw_more (1).svg";
import convertDate from "../../utils/ConvertDate";

function TransReciTable({
  data,
  setSelectedReceipt,
  setOpen,
  selectedIds,
  setSelectedIds,
}) {
  return (
    <div className="w-[100%] mt-[24px]">
      <table className="w-[100%] hidden lg:table">
        <thead className="bg-[#F1F1F1] text-[0.875rem]">
          <tr>
            <td className="pl-[16px] py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center gap-[8px]">
                User
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Date
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Discount Code
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Discount %
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Comment
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]">
              {" "}
              <div className="flex items-center justify-center gap-[8px]">
                Status
                <img src={Sorting} alt="" />
              </div>
            </td>
            <td className="py-[8px] text-[#62646A99] font-poppins lg:w-[14.3%]"></td>
          </tr>
        </thead>
        <tbody className="text-[0.875rem]">
          {data?.map((da) => (
            <tr key={da._id}>
              <td className="pl-[16px] py-[16px] text-[#333333] font-medium flex font-poppins">
                <input
                  checked={selectedIds.includes(da.id)}
                  onClick={() => {
                    if (selectedIds.includes(da.id)) {
                      let filtered = selectedIds.filter((ids) => ids !== da.id);
                      setSelectedIds(filtered);
                    } else {
                      setSelectedIds([...selectedIds, da.id]);
                    }
                  }}
                  type="checkbox"
                  className="mr-2"
                />
                <div>
                  {da.user.firstname}
                  <p className="text-[#62646A99] text-[0.75rem] font-medium">
                    {da.user.email}
                  </p>
                </div>
              </td>
              <td className="py-[16px] text-[#333333] font-medium font-poppins text-center">
                {convertDate(da.date)}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.discount?.code || "No code"}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.discount?.discount_percentage || 0}
              </td>
              <td className="py-[16px] text-[#333333] text-center font-medium font-poppins">
                {da.comment?.length > 0 ? da.comment : "No comment"}
              </td>
              <td className="py-[16px] font-medium font-poppins flex items-center justify-center">
                <p
                  className={`${
                    da.receipt_status.toLowerCase() === "confirmed"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : da.receipt_status.toLowerCase() === "unprocessed"
                      ? "bg-[#EDBB071A] text-[#EDBB07]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 w-max text-center py-1`}
                >
                  {da.receipt_status}
                </p>
              </td>

              <td className="text-[#333333] font-medium font-poppins">
                <div
                  onClick={() => {
                    setSelectedReceipt(da);
                    setOpen(true);
                  }}
                  className="py-[16px] flex items-center justify-center cursor-pointer"
                >
                  <img src={More} alt="" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="lg:hidden flex flex-col gap-[16px] w-[100%]">
        {data.map((da) => (
          <div
            key={da._id}
            className="flex flex-col border border-[#C8C8C8] rounded-lg p-4 space-y-6 font-poppins"
          >
            <div className="flex justify-between cursor-pointer">
              <input
                checked={selectedIds.includes(da.id)}
                onClick={() => {
                  if (selectedIds.includes(da.id)) {
                    let filtered = selectedIds.filter((ids) => ids !== da.id);
                    setSelectedIds(filtered);
                  } else {
                    setSelectedIds([...selectedIds, da.id]);
                  }
                }}
                type="checkbox"
                className="mr-2"
              />
              <img
                onClick={() => {
                  setSelectedReceipt(da);
                  setOpen(true);
                }}
                src={More}
                alt=""
              />
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">USER</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.user.firstname}
                  <p className="text-[#62646A99] text-[0.75rem] font-medium">
                    {da.user.email}
                  </p>
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">DATE</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {convertDate(da.date)}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-6">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  DISCOUNT CODE
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.discount?.code || "No code"}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">
                  DISCOUNT %
                </p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {" "}
                  {da.discount?.discount_percentage || 0}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">COMMENT</p>
                <p className="text-[0.875rem] text-[#333333] font-medium">
                  {da.comment?.length > 0 ? da.comment : "No comment"}
                </p>
              </div>
              <div>
                <p className="text-[0.75rem] text-[#62646A99] mb-1">STATUS</p>
                <p
                  className={`${
                    da.receipt_status.toLowerCase() === "confirmed"
                      ? "bg-[#72E52C1A] text-[#72E52C]"
                      : da.receipt_status.toLowerCase() === "unprocessed"
                      ? "bg-[#EDBB071A] text-[#EDBB07]"
                      : "bg-[#B410071A] text-[#B41007]"
                  } text-[0.875rem] font-medium rounded-[200px] px-2 w-max text-center py-1`}
                >
                  {da.receipt_status}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TransReciTable;
