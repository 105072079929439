/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { CSVLink } from "react-csv";
import Search from "../components/assets/flowbite_search-outline (1).svg";
import { Triangle } from "react-loader-spinner";
import Chevron from "../components/assets/ep_arrow-up-bold.svg";
import axiosInstance from "../axios";
import MembersTable from "../components/Tables/MembersTable";
import { Dialog, DialogPanel } from "@headlessui/react";
import { toast } from "react-toastify";

function Team() {
  const [data, setData] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [newUrl, setNewUrl] = useState(null);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterBy, setFilterBy] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  const [refresh, setRefresh] = useState(false);
  const [count, setCount] = useState(0);
  const CSVdata = data || [];
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const [memberDetails, setMemberDetails] = useState({
    group_name: "Super Admin",
    user_id: "",
  });
  const [selectedMember, setSelectedMember] = useState();

  console.log(memberDetails);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 1000); // 300ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${newUrl || "dashboard/staff-list/"}`)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setNextUrl(res.data.next);
        setPrevUrl(res.data.previous);
        setTotal(res.data.count);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [newUrl, refresh]);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await axiosInstance.get(
          `/dashboard/users-list/${debouncedTerm}/`
        );
        // console.log(response.data);
        setNextUrl(response.data.next);
        setPrevUrl(response.data.previous);
        setTotal(response.data.count);
        if (debouncedTerm === response?.data?.results[0]?.user?.email) {
          setMemberDetails({
            ...memberDetails,
            user_id: response.data.results[0].user.id,
          });
        } else {
          setMemberDetails({
            ...memberDetails,
            user_id: "",
          });
          toast.error("The email you provided is not a registered user", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        // setData(response.data.results);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching search results", error);
      }
    };

    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTerm]);

  function deactivateUser() {
    setCreateLoading(true);
    toast.info("Please Wait...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    let formdata = new FormData();
    formdata.append("user_id", memberDetails.user_id);
    formdata.append("group_name", memberDetails.group_name);
    formdata.append("action", "add");
    axiosInstance
      .post(`/dashboard/attach-permission/`, formdata)
      .then((res) => {
        // console.log(res);
        setCreateLoading(false);
        setRefresh(!refresh);
        setOpenAdd(false);
        toast.success("Successfully added team member", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setMemberDetails({
          group_name: "Super Admin",
          user_id: "",
        });
        // setSelectedUserAccounts([...res.data.accounts, ...res.data.scas]);
        // setNextUrl(res.data.next);
        // setPrevUrl(res.data.previous);
        // setTotal(res.data.count);
        // setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
        setCreateLoading(false);
        toast.error(err.response.data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  function removePermission() {
    setRemoveLoading(true);
    toast.info("Please Wait...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    let formdata = new FormData();
    formdata.append("user_id", selectedMember?.id);
    formdata.append("group_name", selectedMember?.name);
    formdata.append("action", "remove");
    axiosInstance
      .post(`/dashboard/attach-permission/`, formdata)
      .then((res) => {
        // console.log(res);
        setRemoveLoading(false);
        setRefresh(!refresh);
        setOpenDelete(false);
        toast.success("Successfully removed permission", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setSelectedMember();
        // setSelectedUserAccounts([...res.data.accounts, ...res.data.scas]);
        // setNextUrl(res.data.next);
        // setPrevUrl(res.data.previous);
        // setTotal(res.data.count);
        // setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
        setRemoveLoading(false);
        toast.error(err.response.data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }
  function editPermission() {
    setRemoveLoading(true);
    toast.info("Please Wait...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    let formdata = new FormData();
    formdata.append("user_id", selectedMember?.id);
    formdata.append("group_name", memberDetails.group_name);
    formdata.append("action", "add");
    axiosInstance
      .post(`/dashboard/attach-permission/`, formdata)
      .then((res) => {
        // console.log(res);
        setRemoveLoading(false);
        setRefresh(!refresh);
        setOpenDelete(false);
        toast.success("Successfully edited permission", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setSelectedMember();
        // setSelectedUserAccounts([...res.data.accounts, ...res.data.scas]);
        // setNextUrl(res.data.next);
        // setPrevUrl(res.data.previous);
        // setTotal(res.data.count);
        // setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
        setRemoveLoading(false);
        toast.error(err.response.data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="lg:w-[82%] w-[100%]">
        <Topbar />
        <div className="font-poppins lg:p-8 p-4 text-[0.875rem] h-[88vh] overflow-y-scroll">
          <div className="lg:border border-[#E4E4E4] rounded-lg lg:p-6">
            <h1 className="text-center font-medium text-[1.125rem] mb-6">
              Team Permission
            </h1>
            <div className="lg:border border-[#E4E4E4] rounded-lg lg:p-6">
              <div className="mb-6">
                <h1 className="mb-4">Full access</h1>
                <div className="flex flex-col lg:flex-row lg:items-center justify-between border border-[#E4E4E4] rounded-lg p-1 text-[#333333]">
                  <p className="bg-[#F1F1F1] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Super Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    View-only Super Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Payment Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Payout Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Account Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Affiliate Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Chatbot-only Admin
                  </p>
                </div>
              </div>
              <div className="mb-6">
                <h1 className="mb-4">View-only access</h1>
                <div className="flex flex-col lg:flex-row lg:items-center justify-between border border-[#E4E4E4] rounded-lg p-1 text-[#333333]">
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Super Admin
                  </p>
                  <p className="bg-[#F1F1F1] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    View-only Super Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Payment Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Payout Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Account Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Affiliate Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Chatbot-only Admin
                  </p>
                </div>
              </div>
              <div className="mb-6">
                <h1 className="mb-4">Payment access</h1>
                <div className="flex flex-col lg:flex-row lg:items-center justify-between border border-[#E4E4E4] rounded-lg p-1 text-[#333333]">
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Super Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    View-only Super Admin
                  </p>
                  <p className="bg-[#F1F1F1] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Payment Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Payout Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Account Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Affiliate Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Chatbot-only Admin
                  </p>
                </div>
              </div>
              <div className="mb-6">
                <h1 className="mb-4">Payout access</h1>
                <div className="flex flex-col lg:flex-row lg:items-center justify-between border border-[#E4E4E4] rounded-lg p-1 text-[#333333]">
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Super Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    View-only Super Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Payment Admin
                  </p>
                  <p className="bg-[#F1F1F1] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Payout Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Account Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Affiliate Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Chatbot-only Admin
                  </p>
                </div>
              </div>
              <div className="mb-6">
                <h1 className="mb-4">Create trading account</h1>
                <div className="flex flex-col lg:flex-row lg:items-center justify-between border border-[#E4E4E4] rounded-lg p-1 text-[#333333]">
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Super Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    View-only Super Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Payment Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Payout Admin
                  </p>
                  <p className="bg-[#F1F1F1] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Account Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Affiliate Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Chatbot-only Admin
                  </p>
                </div>
              </div>
              <div className="mb-6">
                <h1 className="mb-4">Affilliate related access</h1>
                <div className="flex flex-col lg:flex-row lg:items-center justify-between border border-[#E4E4E4] rounded-lg p-1 text-[#333333]">
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Super Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    View-only Super Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Payment Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Payout Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Account Admin
                  </p>
                  <p className="bg-[#F1F1F1] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Affiliate Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Chatbot-only Admin
                  </p>
                </div>
              </div>
              <div className="mb-6">
                <h1 className="mb-4">Chatbot-only access</h1>
                <div className="flex flex-col lg:flex-row lg:items-center justify-between border border-[#E4E4E4] rounded-lg p-1 text-[#333333]">
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Super Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    View-only Super Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Payment Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Payout Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Account Admin
                  </p>
                  <p className="text-[#62646A99] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Affiliate Admin
                  </p>
                  <p className="bg-[#F1F1F1] lg:text-center rounded-lg py-2 lg:px-6 px-2">
                    Chatbot-only Admin
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="border border-[#E4E4E4] rounded-lg p-6 mt-10">
            <div className="flex items-center justify-end mb-6">
              <button
                onClick={() => setOpenAdd(true)}
                className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
              >
                Add Team Member(s)
              </button>
            </div>
            <div className="border border-[#E4E4E4] rounded-[8px] py-[24px] hidden lg:block">
              <div className="flex justify-between px-[16px]">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Team Members List
                </h1>
                <div className="flex items-center gap-10 text-[0.875rem]">
                  <CSVLink data={CSVdata} filename={"sca_list_data.csv"}>
                    <button className="text-[#3EB211] font-poppins font-medium">
                      Export CSV
                    </button>
                  </CSVLink>
                  <div className="flex items-center border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterBy(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  {/* <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                    Filter
                    <img src={Chevron} alt="" />
                  </button> */}
                  <div className="flex items-center gap-5 font-poppins font-medium">
                    {prevUrl && (
                      <button
                        onClick={() => {
                          setNewUrl(prevUrl);
                        }}
                        className="bg-white text-[#3EB211] border border-[#3EB211] py-2 px-4 rounded-lg"
                      >
                        Prev
                      </button>
                    )}
                    {/* <div>
                    Showing: {data?.length} users per page
                    <p>Total users: {total.toLocaleString()}</p>
                  </div> */}
                    {nextUrl && (
                      <button
                        onClick={() => {
                          setCount(count + 1);
                          setNewUrl(nextUrl);
                        }}
                        className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {loading ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <MembersTable
                  data={data}
                  setOpenDelete={setOpenDelete}
                  setOpenEdit={setOpenEdit}
                  setSelectedMember={setSelectedMember}
                />
              )}
            </div>
            <div className="lg:hidden">
              <div className="flex items-center justify-between">
                <h1 className="font-poppins text-[#333333] font-medium text-[1.125rem]">
                  Members List
                </h1>

                <CSVLink data={CSVdata} filename={"sca_list_data.csv"}>
                  <button className="text-[#3EB211] font-poppins font-medium">
                    Export CSV
                  </button>
                </CSVLink>
              </div>
              <div className="flex flex-col items-center gap-10 text-[0.875rem] mt-[24px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center w-[50%] border-b border-b-[#1D1D1D] px-2 pb-2">
                    <img src={Search} alt="" />
                    <input
                      onChange={(e) => setFilterBy(e.target.value)}
                      type="text"
                      placeholder="Search"
                      className="px-3 outline-none font-poppins"
                    />
                  </div>
                  {/* <button className="border border-[#E4E4E4] flex font-medium items-center font-poppins px-2 py-1 rounded-[8px] gap-[8px]">
                    Filter
                    <img src={Chevron} alt="" />
                  </button> */}
                </div>
                <div className="flex items-center gap-5 font-poppins font-medium">
                  {prevUrl && (
                    <button
                      onClick={() => {
                        setNewUrl(prevUrl);
                      }}
                      className="bg-white text-[#3EB211] border border-[#3EB211] py-2 px-4 rounded-lg"
                    >
                      Prev
                    </button>
                  )}
                  {nextUrl && (
                    <button
                      onClick={() => {
                        setCount(count + 1);
                        setNewUrl(nextUrl);
                      }}
                      className="bg-[#3EB211] border border-[#3EB211] text-white rounded-lg py-2 px-4"
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
              {loading ? (
                <div className="w-[100%] h-[50vh] flex items-center justify-center">
                  <Triangle
                    visible={true}
                    height="80"
                    width="80"
                    color="#3EB211"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <MembersTable
                  data={data}
                  setOpenDelete={setOpenDelete}
                  setOpenEdit={setOpenEdit}
                  setSelectedMember={setSelectedMember}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={openAdd}
        onClose={() => {
          setOpenAdd(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/50 justify-center p-4">
          <DialogPanel className="rounded-lg font-poppins space-y-6 border bg-white p-6 w-[600px] lg:max-h-[700px] max-h-[600px] overflow-y-scroll">
            <div className="flex items-center justify-end">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="cursor-pointer"
                onClick={() => {
                  setOpenAdd(false);
                }}
              >
                <path
                  d="M12.7001 3.97283C12.6385 3.91103 12.5652 3.862 12.4846 3.82854C12.4039 3.79509 12.3175 3.77787 12.2301 3.77787C12.1428 3.77787 12.0564 3.79509 11.9757 3.82854C11.8951 3.862 11.8218 3.91103 11.7601 3.97283L8.50015 7.22616L5.24015 3.96616C5.17843 3.90444 5.10515 3.85548 5.02451 3.82208C4.94387 3.78868 4.85744 3.77148 4.77015 3.77148C4.68286 3.77148 4.59643 3.78868 4.51579 3.82208C4.43514 3.85548 4.36187 3.90444 4.30015 3.96616C4.23843 4.02789 4.18947 4.10116 4.15606 4.1818C4.12266 4.26245 4.10547 4.34888 4.10547 4.43616C4.10547 4.52345 4.12266 4.60988 4.15606 4.69053C4.18947 4.77117 4.23843 4.84444 4.30015 4.90616L7.56015 8.16616L4.30015 11.4262C4.23843 11.4879 4.18947 11.5612 4.15606 11.6418C4.12266 11.7224 4.10547 11.8089 4.10547 11.8962C4.10547 11.9835 4.12266 12.0699 4.15606 12.1505C4.18947 12.2312 4.23843 12.3044 4.30015 12.3662C4.36187 12.4279 4.43514 12.4768 4.51579 12.5102C4.59643 12.5437 4.68286 12.5608 4.77015 12.5608C4.85744 12.5608 4.94387 12.5437 5.02451 12.5102C5.10515 12.4768 5.17843 12.4279 5.24015 12.3662L8.50015 9.10616L11.7601 12.3662C11.8219 12.4279 11.8951 12.4768 11.9758 12.5102C12.0564 12.5437 12.1429 12.5608 12.2301 12.5608C12.3174 12.5608 12.4039 12.5437 12.4845 12.5102C12.5652 12.4768 12.6384 12.4279 12.7001 12.3662C12.7619 12.3044 12.8108 12.2312 12.8442 12.1505C12.8776 12.0699 12.8948 11.9835 12.8948 11.8962C12.8948 11.8089 12.8776 11.7224 12.8442 11.6418C12.8108 11.5612 12.7619 11.4879 12.7001 11.4262L9.44015 8.16616L12.7001 4.90616C12.9535 4.65283 12.9535 4.22616 12.7001 3.97283Z"
                  fill="#333333"
                />
              </svg>
            </div>
            <div className="flex items-center flex-col justify-center border-b border-b-[#E4E4E4] pb-6">
              <p className="text-center text-[1.125rem] font-medium text-[#333333] mb-2">
                Add Member
              </p>
            </div>

            {/* <label htmlFor="csv_upload">
              <p>Upload to CSV file</p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <g clip-path="url(#clip0_885_18757)">
                    <path
                      d="M8.08333 6.50017V10.5002C8.08352 10.6701 8.14859 10.8335 8.26523 10.9571C8.38188 11.0806 8.5413 11.155 8.71093 11.1649C8.88056 11.1749 9.04759 11.1197 9.17789 11.0107C9.30819 10.9016 9.39193 10.7469 9.412 10.5782L9.41667 10.5002V6.50017H13.4167C13.7531 6.50006 14.077 6.6271 14.3237 6.85583C14.5704 7.08456 14.7214 7.39806 14.7467 7.7335L14.75 7.8335V13.8335C14.7501 14.1699 14.6231 14.4939 14.3943 14.7405C14.1656 14.9872 13.8521 15.1383 13.5167 15.1635L13.4167 15.1668H4.08333C3.74695 15.1669 3.42296 15.0399 3.1763 14.8112C2.92965 14.5824 2.77856 14.2689 2.75333 13.9335L2.75 13.8335V7.8335C2.74989 7.49711 2.87694 7.17312 3.10566 6.92647C3.33439 6.67981 3.6479 6.52873 3.98333 6.5035L4.08333 6.50017H8.08333ZM9.33933 2.14617L11.5787 4.38483C11.7038 4.50993 11.774 4.67959 11.774 4.8565C11.774 5.03341 11.7038 5.20307 11.5787 5.32817C11.4536 5.45326 11.2839 5.52354 11.107 5.52354C10.9301 5.52354 10.7604 5.45326 10.6353 5.32817L9.41667 4.10883V6.50017H8.08333V4.10883L6.86467 5.32817C6.80273 5.39011 6.72919 5.43924 6.64826 5.47276C6.56734 5.50628 6.4806 5.52354 6.393 5.52354C6.3054 5.52354 6.21866 5.50628 6.13774 5.47276C6.05681 5.43924 5.98327 5.39011 5.92133 5.32817C5.85939 5.26623 5.81026 5.19269 5.77674 5.11176C5.74322 5.03083 5.72596 4.9441 5.72596 4.8565C5.72596 4.7689 5.74322 4.68216 5.77674 4.60123C5.81026 4.52031 5.85939 4.44677 5.92133 4.38483L8.16133 2.14617C8.31759 1.99004 8.52944 1.90234 8.75033 1.90234C8.97122 1.90234 9.18307 1.99004 9.33933 2.14617Z"
                      fill="#1E1E1E"
                      fill-opacity="0.7"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_885_18757">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.75 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>{" "}
                Choose a file
              </p>
              <input id="csv_upload" type="file" className="hidden" />
            </label> */}
            <div className="space-y-6 border border-[#E4E4E4] rounded-lg p-6">
              <div className="w-full space-y-2">
                <p>Role</p>
                <select
                  name=""
                  id=""
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded w-full"
                  onChange={(e) =>
                    setMemberDetails({
                      ...memberDetails,
                      group_name: e.target.value,
                    })
                  }
                >
                  <option value="Super Admin">Super Admin</option>
                  <option value="Payments Admin">Payments Admin</option>
                  <option value="Payout Admin">Payout Admin</option>
                  <option value="Affiliate Admin">Affiliate Admin</option>
                  <option value="Account Admin">Account Admin</option>
                  <option value="View-only Super Admin">
                    View-only Super Admin
                  </option>
                  <option value="Chatbot-only">Chatbot-only Admin</option>
                </select>
              </div>
              <div className="w-full space-y-2">
                <p>Email</p>
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded w-full"
                />
              </div>
            </div>

            <button
              disabled={memberDetails.user_id === ""}
              onClick={() => {
                deactivateUser();
              }}
              className="bg-[#3EB211] text-white rounded-md py-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
            >
              {createLoading ? "Please wait..." : "Done"}
            </button>
            <button
              onClick={() => {
                setMemberDetails({
                  group_name: "",
                  user_id: "",
                });
                setOpenAdd(false);
              }}
              className="bg-[#B41007] text-white rounded-md py-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
            >
              {"Cancel"}
            </button>
          </DialogPanel>
        </div>
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={() => {
          setOpenDelete(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/50 justify-center p-4">
          <DialogPanel className="rounded-lg font-poppins space-y-6 border bg-white p-6 w-[600px] lg:max-h-[700px] max-h-[600px] overflow-y-scroll">
            <div className="flex items-center justify-end">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="cursor-pointer"
                onClick={() => {
                  setOpenDelete(false);
                }}
              >
                <path
                  d="M12.7001 3.97283C12.6385 3.91103 12.5652 3.862 12.4846 3.82854C12.4039 3.79509 12.3175 3.77787 12.2301 3.77787C12.1428 3.77787 12.0564 3.79509 11.9757 3.82854C11.8951 3.862 11.8218 3.91103 11.7601 3.97283L8.50015 7.22616L5.24015 3.96616C5.17843 3.90444 5.10515 3.85548 5.02451 3.82208C4.94387 3.78868 4.85744 3.77148 4.77015 3.77148C4.68286 3.77148 4.59643 3.78868 4.51579 3.82208C4.43514 3.85548 4.36187 3.90444 4.30015 3.96616C4.23843 4.02789 4.18947 4.10116 4.15606 4.1818C4.12266 4.26245 4.10547 4.34888 4.10547 4.43616C4.10547 4.52345 4.12266 4.60988 4.15606 4.69053C4.18947 4.77117 4.23843 4.84444 4.30015 4.90616L7.56015 8.16616L4.30015 11.4262C4.23843 11.4879 4.18947 11.5612 4.15606 11.6418C4.12266 11.7224 4.10547 11.8089 4.10547 11.8962C4.10547 11.9835 4.12266 12.0699 4.15606 12.1505C4.18947 12.2312 4.23843 12.3044 4.30015 12.3662C4.36187 12.4279 4.43514 12.4768 4.51579 12.5102C4.59643 12.5437 4.68286 12.5608 4.77015 12.5608C4.85744 12.5608 4.94387 12.5437 5.02451 12.5102C5.10515 12.4768 5.17843 12.4279 5.24015 12.3662L8.50015 9.10616L11.7601 12.3662C11.8219 12.4279 11.8951 12.4768 11.9758 12.5102C12.0564 12.5437 12.1429 12.5608 12.2301 12.5608C12.3174 12.5608 12.4039 12.5437 12.4845 12.5102C12.5652 12.4768 12.6384 12.4279 12.7001 12.3662C12.7619 12.3044 12.8108 12.2312 12.8442 12.1505C12.8776 12.0699 12.8948 11.9835 12.8948 11.8962C12.8948 11.8089 12.8776 11.7224 12.8442 11.6418C12.8108 11.5612 12.7619 11.4879 12.7001 11.4262L9.44015 8.16616L12.7001 4.90616C12.9535 4.65283 12.9535 4.22616 12.7001 3.97283Z"
                  fill="#333333"
                />
              </svg>
            </div>
            <div className="flex items-center flex-col justify-center border-b border-b-[#E4E4E4] pb-6">
              <p className="text-center text-[1.125rem] font-medium text-[#333333] mb-2">
                Remove Permission
              </p>
              <p className="text-[#62646A99] text-[0.75rem] font-medium">
                Are you sure you want to remove permission from{" "}
                {selectedMember?.user}
              </p>
            </div>

            {/* <label htmlFor="csv_upload">
              <p>Upload to CSV file</p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <g clip-path="url(#clip0_885_18757)">
                    <path
                      d="M8.08333 6.50017V10.5002C8.08352 10.6701 8.14859 10.8335 8.26523 10.9571C8.38188 11.0806 8.5413 11.155 8.71093 11.1649C8.88056 11.1749 9.04759 11.1197 9.17789 11.0107C9.30819 10.9016 9.39193 10.7469 9.412 10.5782L9.41667 10.5002V6.50017H13.4167C13.7531 6.50006 14.077 6.6271 14.3237 6.85583C14.5704 7.08456 14.7214 7.39806 14.7467 7.7335L14.75 7.8335V13.8335C14.7501 14.1699 14.6231 14.4939 14.3943 14.7405C14.1656 14.9872 13.8521 15.1383 13.5167 15.1635L13.4167 15.1668H4.08333C3.74695 15.1669 3.42296 15.0399 3.1763 14.8112C2.92965 14.5824 2.77856 14.2689 2.75333 13.9335L2.75 13.8335V7.8335C2.74989 7.49711 2.87694 7.17312 3.10566 6.92647C3.33439 6.67981 3.6479 6.52873 3.98333 6.5035L4.08333 6.50017H8.08333ZM9.33933 2.14617L11.5787 4.38483C11.7038 4.50993 11.774 4.67959 11.774 4.8565C11.774 5.03341 11.7038 5.20307 11.5787 5.32817C11.4536 5.45326 11.2839 5.52354 11.107 5.52354C10.9301 5.52354 10.7604 5.45326 10.6353 5.32817L9.41667 4.10883V6.50017H8.08333V4.10883L6.86467 5.32817C6.80273 5.39011 6.72919 5.43924 6.64826 5.47276C6.56734 5.50628 6.4806 5.52354 6.393 5.52354C6.3054 5.52354 6.21866 5.50628 6.13774 5.47276C6.05681 5.43924 5.98327 5.39011 5.92133 5.32817C5.85939 5.26623 5.81026 5.19269 5.77674 5.11176C5.74322 5.03083 5.72596 4.9441 5.72596 4.8565C5.72596 4.7689 5.74322 4.68216 5.77674 4.60123C5.81026 4.52031 5.85939 4.44677 5.92133 4.38483L8.16133 2.14617C8.31759 1.99004 8.52944 1.90234 8.75033 1.90234C8.97122 1.90234 9.18307 1.99004 9.33933 2.14617Z"
                      fill="#1E1E1E"
                      fill-opacity="0.7"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_885_18757">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.75 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>{" "}
                Choose a file
              </p>
              <input id="csv_upload" type="file" className="hidden" />
            </label> */}
            {/* <div className="space-y-6 border border-[#E4E4E4] rounded-lg p-6">
              <div className="w-full space-y-2">
                <p>Role</p>
                <select
                  name=""
                  id=""
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded w-full"
                  onChange={(e) =>
                    setMemberDetails({
                      ...memberDetails,
                      group_name: e.target.value,
                    })
                  }
                >
                  <option value="Super Admin">Super Admin</option>
                  <option value="Payments Admin">Payments Admin</option>
                  <option value="Payout Admin">Payout Admin</option>
                  <option value="Affiliate Admin">Affiliate Admin</option>
                  <option value="Accounts Admin">Accounts Admin</option>
                  <option value="View-Only Super Admin">
                    View-Only Super Admin
                  </option>
                </select>
              </div>
              <div className="w-full space-y-2">
                <p>Email</p>
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded w-full"
                />
              </div>
            </div> */}

            <button
              // disabled={memberDetails.user_id === ""}
              onClick={() => {
                removePermission();
              }}
              className="bg-[#3EB211] text-white rounded-md py-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
            >
              {removeLoading ? "Please wait..." : "Yes"}
            </button>
            <button
              onClick={() => {
                setSelectedMember();
                setOpenAdd(false);
              }}
              className="bg-[#B41007] text-white rounded-md py-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
            >
              {"Cancel"}
            </button>
          </DialogPanel>
        </div>
      </Dialog>
      <Dialog
        open={openEdit}
        onClose={() => {
          setOpenEdit(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center bg-black/50 justify-center p-4">
          <DialogPanel className="rounded-lg font-poppins space-y-6 border bg-white p-6 w-[600px] lg:max-h-[700px] max-h-[600px] overflow-y-scroll">
            <div className="flex items-center justify-end">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="cursor-pointer"
                onClick={() => {
                  setOpenEdit(false);
                }}
              >
                <path
                  d="M12.7001 3.97283C12.6385 3.91103 12.5652 3.862 12.4846 3.82854C12.4039 3.79509 12.3175 3.77787 12.2301 3.77787C12.1428 3.77787 12.0564 3.79509 11.9757 3.82854C11.8951 3.862 11.8218 3.91103 11.7601 3.97283L8.50015 7.22616L5.24015 3.96616C5.17843 3.90444 5.10515 3.85548 5.02451 3.82208C4.94387 3.78868 4.85744 3.77148 4.77015 3.77148C4.68286 3.77148 4.59643 3.78868 4.51579 3.82208C4.43514 3.85548 4.36187 3.90444 4.30015 3.96616C4.23843 4.02789 4.18947 4.10116 4.15606 4.1818C4.12266 4.26245 4.10547 4.34888 4.10547 4.43616C4.10547 4.52345 4.12266 4.60988 4.15606 4.69053C4.18947 4.77117 4.23843 4.84444 4.30015 4.90616L7.56015 8.16616L4.30015 11.4262C4.23843 11.4879 4.18947 11.5612 4.15606 11.6418C4.12266 11.7224 4.10547 11.8089 4.10547 11.8962C4.10547 11.9835 4.12266 12.0699 4.15606 12.1505C4.18947 12.2312 4.23843 12.3044 4.30015 12.3662C4.36187 12.4279 4.43514 12.4768 4.51579 12.5102C4.59643 12.5437 4.68286 12.5608 4.77015 12.5608C4.85744 12.5608 4.94387 12.5437 5.02451 12.5102C5.10515 12.4768 5.17843 12.4279 5.24015 12.3662L8.50015 9.10616L11.7601 12.3662C11.8219 12.4279 11.8951 12.4768 11.9758 12.5102C12.0564 12.5437 12.1429 12.5608 12.2301 12.5608C12.3174 12.5608 12.4039 12.5437 12.4845 12.5102C12.5652 12.4768 12.6384 12.4279 12.7001 12.3662C12.7619 12.3044 12.8108 12.2312 12.8442 12.1505C12.8776 12.0699 12.8948 11.9835 12.8948 11.8962C12.8948 11.8089 12.8776 11.7224 12.8442 11.6418C12.8108 11.5612 12.7619 11.4879 12.7001 11.4262L9.44015 8.16616L12.7001 4.90616C12.9535 4.65283 12.9535 4.22616 12.7001 3.97283Z"
                  fill="#333333"
                />
              </svg>
            </div>
            <div className="flex items-center flex-col justify-center border-b border-b-[#E4E4E4] pb-6">
              <p className="text-center text-[1.125rem] font-medium text-[#333333] mb-2">
                Edit Permission
              </p>
              <p className="text-[#62646A99] text-[0.75rem] font-medium">
                Edit permission of {selectedMember?.user}
              </p>
              <p className="text-[#333333] text-[0.75rem] font-medium">
                Current permission: {selectedMember?.name}
              </p>
            </div>

            {/* <label htmlFor="csv_upload">
              <p>Upload to CSV file</p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <g clip-path="url(#clip0_885_18757)">
                    <path
                      d="M8.08333 6.50017V10.5002C8.08352 10.6701 8.14859 10.8335 8.26523 10.9571C8.38188 11.0806 8.5413 11.155 8.71093 11.1649C8.88056 11.1749 9.04759 11.1197 9.17789 11.0107C9.30819 10.9016 9.39193 10.7469 9.412 10.5782L9.41667 10.5002V6.50017H13.4167C13.7531 6.50006 14.077 6.6271 14.3237 6.85583C14.5704 7.08456 14.7214 7.39806 14.7467 7.7335L14.75 7.8335V13.8335C14.7501 14.1699 14.6231 14.4939 14.3943 14.7405C14.1656 14.9872 13.8521 15.1383 13.5167 15.1635L13.4167 15.1668H4.08333C3.74695 15.1669 3.42296 15.0399 3.1763 14.8112C2.92965 14.5824 2.77856 14.2689 2.75333 13.9335L2.75 13.8335V7.8335C2.74989 7.49711 2.87694 7.17312 3.10566 6.92647C3.33439 6.67981 3.6479 6.52873 3.98333 6.5035L4.08333 6.50017H8.08333ZM9.33933 2.14617L11.5787 4.38483C11.7038 4.50993 11.774 4.67959 11.774 4.8565C11.774 5.03341 11.7038 5.20307 11.5787 5.32817C11.4536 5.45326 11.2839 5.52354 11.107 5.52354C10.9301 5.52354 10.7604 5.45326 10.6353 5.32817L9.41667 4.10883V6.50017H8.08333V4.10883L6.86467 5.32817C6.80273 5.39011 6.72919 5.43924 6.64826 5.47276C6.56734 5.50628 6.4806 5.52354 6.393 5.52354C6.3054 5.52354 6.21866 5.50628 6.13774 5.47276C6.05681 5.43924 5.98327 5.39011 5.92133 5.32817C5.85939 5.26623 5.81026 5.19269 5.77674 5.11176C5.74322 5.03083 5.72596 4.9441 5.72596 4.8565C5.72596 4.7689 5.74322 4.68216 5.77674 4.60123C5.81026 4.52031 5.85939 4.44677 5.92133 4.38483L8.16133 2.14617C8.31759 1.99004 8.52944 1.90234 8.75033 1.90234C8.97122 1.90234 9.18307 1.99004 9.33933 2.14617Z"
                      fill="#1E1E1E"
                      fill-opacity="0.7"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_885_18757">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.75 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>{" "}
                Choose a file
              </p>
              <input id="csv_upload" type="file" className="hidden" />
            </label> */}
            <div className="space-y-6 border border-[#E4E4E4] rounded-lg p-6">
              <div className="w-full space-y-2">
                <p>Role</p>
                <select
                  name=""
                  id=""
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded w-full"
                  onChange={(e) =>
                    setMemberDetails({
                      ...memberDetails,
                      group_name: e.target.value,
                    })
                  }
                >
                  <option value="Super Admin">Super Admin</option>
                  <option value="Payments Admin">Payments Admin</option>
                  <option value="Payout Admin">Payout Admin</option>
                  <option value="Affiliate Admin">Affiliate Admin</option>
                  <option value="Account Admin">Account Admin</option>
                  <option value="View-only Super Admin">
                    View-Only Super Admin
                  </option>
                  <option value="Chatbot-only">Chatbot-only Admin</option>
                </select>
              </div>
              {/* <div className="w-full space-y-2">
                <p>Email</p>
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="border border-[#333333] py-1 px-3 outline-none #1D1D1D rounded w-full"
                />
              </div> */}
            </div>

            <button
              // disabled={memberDetails.user_id === ""}
              onClick={() => {
                editPermission();
              }}
              className="bg-[#3EB211] text-white rounded-md py-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
            >
              {removeLoading ? "Please wait..." : "Done"}
            </button>
            <button
              onClick={() => {
                setSelectedMember();
                setOpenAdd(false);
              }}
              className="bg-[#B41007] text-white rounded-md py-3 w-[100%] disabled:bg-gray-400 disabled:text-gray-900"
            >
              {"Cancel"}
            </button>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}

export default Team;
